import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import Close3x  from '../assets/icons/Close@3x.svg'
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    // dark: true,
    options: { customProperties: true },
    themes: {
      light: {
        // primary: '#1D3557',
        primary: "#893393",
        landingPrimary: "#893393",
        appBar: "#F4F1DE",
        secondary: "#5887fa",
        button: "#1D77AF",
        weather: "#3C88FF",
        black2: "#1D3557",
        red: "#FF1F1F",
        green: "#27AE62",
        yellow: "#EBB000",
        orangeRed: "#FF7C81",
        purple: "#6E38DD",
        darkRed: "#EF5645",
        Gray1: "#F1F1F1",
        CardWrap: "rgb(255, 255, 255, 0.7)",
        rating: "#EDA703",
        brandColor: "#3C88FF",
      },
    },
    icons: {
      values: {
        close3x: {
          component: require("@/assets/icons/Close@3x.svg"),
        },
      },
    },
  },
});
