<!-- example 
https://stackoverflow.com/questions/53708278/how-to-resize-the-image-in-vue
        <ehc-image-upload 
            v-model="picDialog" 
            title="Upload Profile Image" 
            :uploadPath="'/profilePictures/'+user.uid"  
            @upload="setProfilePic($event)" 
            circle
            :size="{
                width: 200,
                height: 200
            }"/>

    opens dialog box to crop and upload image the name passed to it in imgPath
    once uploaded it closes the dialog box and returns 
    @upload = return download url
-->


<template>
    <ehc-dialog v-model="show" :title="title" width="500" :persistent="persistent" :loading="loading">

        <ehc-btn plain @click="$refs.file.click()">
           + Upload a new image
        </ehc-btn>
            <p v-html="instructions"></p> 
        <!-- input is so the button above will work, theres probably a better way to do this -->
        <input type="file" ref="file" @change="loadImage($event)" accept="image/*" v-show="false">

        <cropper
            ref="cropper"
            class="upload-example-cropper"
            :src="image.src ? image.src : null"
            :canvas="canvasSize"
            :stencil-component="stencil"
        />

        <template v-slot:actions>

          <div class="button-container">
            <ehc-btn @click="crop()" class="button-left">
              Apply
            </ehc-btn>
            <ehc-btn color="error" plain @click="show=false" class="button-right">
              Cancel
            </ehc-btn>
          </div>
        </template>
    </ehc-dialog>
</template>

<script>
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

// import 'vue-advanced-cropper/dist/themes/theme.compact.css';
import firebase from 'firebase'


// This function is used to detect the actual image type, 
function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}



export default {
	components: {
		Cropper,
	},
    props: {
        value: Boolean,
        title: String,
        uploadPath: {type: String, required: true},
        size: Object,
        circle: Boolean,
        instructions: {default: "Select image to upload and insert"},
        persistent: {type: Boolean, default: false},
    },
	data() {
		return {
            loading: false,
            show: false,
			image: {
				src: null,
				type: null,
                size: null,
                width: null,
                height: null,
			},
            cropperOptions: {
                resizeImage: false, // resize the image to the canvas size
            },
            cropData: null,
		};
	},
    computed: {
        stencil() {
            return (this.circle) ? "circle-stencil" : "rectangle-stencil"
        },
        canvasSize() {
            try {
                if ( this.image && ( this.image.width < this.size.width || this.image.height < this.size.height ) ) {
                    return {
                        width: this.image.width,
                        height: this.image.height
                    }
                }
                return this.size
            } catch (error) {
                return this.size
            }
        },
    },
    watch: {
        value(val) {
            console.log("value changed", val)
            if (val != this.show) {
                this.show= val
            }
        },
        show(val) {
            if (val != this.value) {
                this.$emit('input', val)
            }
        },

    },
	methods: {
		async crop() {
            this.loading=true
            let imageRef = firebase.storage().ref(this.uploadPath)
            console.log("crop")
			const { canvas } = this.$refs.cropper.getResult();


			canvas.toBlob((blob) => {
                imageRef.put(blob)
                .then((snapshot) => {
                    console.log("uploaded a blob", snapshot.metadata)
                    snapshot.ref.getDownloadURL().then((url) => {
                        // console.log("download url", url)
                        this.$emit('upload', url)
                        this.loading=false
                        this.show=false
                    })
                })
				// Do something with blob: upload to a server, download and etc.
			}, this.image.type);
		},
		reset() {
			this.image = {
				src: null,
				type: null,
                size: null,
                width: null,
                height: null,
			}
		},
        loadImage(event) {
            const { files } = event.target;
            if (files && files[0]) {
                if (this.image.src) {
                URL.revokeObjectURL(this.image.src)
                }
                const blob = URL.createObjectURL(files[0]);
                const reader = new FileReader();
                reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    this.image = {
                        src: blob,
                        type: getMimeType(e.target.result, files[0].type),
                        size: files[0].size,
                        width: img.width, // set the width property of the image object
                        height: img.height // set the height property of the image object
                    };
                    this.setCompanyImageInfo(this.image)
                };
                img.src = blob;
                };
                reader.readAsArrayBuffer(files[0]);
            }
        },
        // use this setCompanyImageInfo store obj in propertyNew to update in property obj
        setCompanyImageInfo(image){
            console.log("setCompanyImageInfo", image)
            let companyImageInfo = {
                width: image.width,
                height: image.height,
                type: image.type,
                size: this.image.size,
            }
            // if ( this.property.companyImageInfo ) this.property.companyImageInfo = companyImageInfo
            this.$store.commit("setCompanyImageInfo", companyImageInfo) // set in store so it can be used in other components especially ehc-branding
        },
        // called from the parent when the user clicks the open button
        clearCropper() {
            // console.log("clearCropper")
            this.image = {
                src: null,
                type: null,
                size: null,
                width: null,
                height: null,
            };
            this.$refs.cropper.reset();
        },
   	},

    destroyed() {
		// Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		if (this.image.src) {
			URL.revokeObjectURL(this.image.src)
		}
	}
};
</script>


<style>
	@import '~vue-advanced-cropper/dist/theme.classic.scss';

    .button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button-left {
  flex-basis: 65%; /* 48% to account for spacing */
}
.button-right {
  flex-basis: 30%; /* 48% to account for spacing */
}

</style>