<!-- when menu item is clicked it emits a click event with the value being the menu item object 
Props:
    v-model= an array of objects containing the values for each menu item
                [{
                    icon:   -optional
                    TODO image:  -optional
                    label:  -label name
                    TODO route:  -optional - if this has a value then the component will go to that rout when clicked
                                        if not it will just return the object
                }]
Emits:
    v-on:click = returns an event with the object that was clicked
-->
<template>
  <div class="ehc-menu">
    <v-card
      v-for="(item, index) in valueFiltered"
      color="white"
      class="rounded-lg"
      height="77px"
      elevation="0"
      :key="index"
      @click="$emit('click', item)"
    >
      <!-- I used toolbar instead of list because we can get the desired look with no direct css editing this way -->
      <v-toolbar flat :class="'pa-0 ma-0 mt-4 toolbar' + inActiveClass(item)">
        <v-overlay
          v-if="item.inActive"
          class="rounded-lg pb-0 mb-0"
          absolute
          color="white"
          opacity=".7"
          z-index="0"
        ></v-overlay>

        <ehc-image-icon v-if="item.icon" :item="item"></ehc-image-icon>

        <span class="pl-2 black2--text text-h6 text-left">{{
          item.label
        }}</span>

        <!-- //Spacer to push the icons to the right -->
        <v-spacer></v-spacer>

        <img
          v-if="item.link && item.link.includes('http') && userIsAuthenticated"
          @click.stop="confirmDeleteClick(item)"
          color="darkRed"
          width="20"
          class="mr-5 mb-1"
          src="@/assets/icons/Close-red.svg"
        />

        <!-- // this is for if we want to make items hidden -->
        <span v-if="item.hiddenAt" class="text-caption"
          >page is currently hidden</span
        >
        <v-icon v-if="item.appendIcon">{{ item.appendIcon }}</v-icon>

        <!-- //Special case for comming soon items -->
        <v-chip
          v-else-if="item.link == 'Coming Soon'"
          small
          color="#6E38DD"
          text-color="white"
          class="margin-0 ehc-comming-soon"
        >
          {{ item.link }}</v-chip
        >

        <!-- // use a different icon for the link if it is a link     -->
        <img
          v-else-if="item.link && item.link.includes('http')"
          src="@/assets/icons/Link 2.png"
        />

        <!-- //default right arrow icon -->
        <img v-else src="@/assets/icons/arrow-right.png" />
      </v-toolbar>
    </v-card>

    <ehc-alert-confirm
      v-model="confirmDelete"
      @confirmAction="deleteItem()"
      @cancel="confirmDelete = false"
      title="Delete Menu Link"
      message="Are you sure you want to delete this menu link?"
    ></ehc-alert-confirm>
  </div>
</template>

<script>
import EhcAlertConfirm from "./ehc-alert-confirm.vue";
import EhcImageIcon from "./ehc-item-icon.vue";

export default {
  mixins: [],
  props: ["value", "type"],
  components: {
    EhcAlertConfirm,
    EhcImageIcon,
  },
  data: () => ({
    confirmDelete: false,
    thisItem: null,
  }),
  created() {},
  watch: {},
  computed: {
    valueFiltered: function () {
      return this.value;
    },
    userIsAuthenticated: function () {
      return this.$store.getters.user.data;
    },
  },
  methods: {
    inActiveClass(item) {
      if (item.inActive === true) {
        return "inActive";
      }
      return "";
    },
    xdeleteItem() {
      console.log("delete item", this.thisItem);
    },
    deleteItem() {
      let payload = {
        type: this.type,
        docId: this.thisItem.docId,
        field: "deletedAt",
      };
      console.log("deleting", payload);

      this.$store
        .dispatch("markCustomSectionDeletedAtOrHiddenAt", payload)
        .catch((err) => {
          console.log("error deleting", err);
        });
    },
    confirmDeleteClick(item) {
      this.thisItem = item;
      this.confirmDelete = true;
    },
    iconHeight(item) {
      if (item.icon === "property-info.svg") {
        return "40px";
      }
      return "0px";
    },

    // goToPageName: function(menuItem){
    //     const routeName = menuItem.link
    //     console.log("routeName: ", routeName)
    //     if ( routeName === 'Coming Soon' ) {
    //         alert("This page is coming soon!")
    //         this.$store.commit('setShowAlert',true)
    //         this.$store.commit('setAlertMessage','Coming Soon')
    //     }
    //     if ( routeName === "BookAgain" ) { this.visitUrl(this.property.bookagain) }
    //     else { this.goToRoute(routeName) }
    // },
  },
};
</script>

<style scoped>
.ehc-menu {
  margin-top: 25px;
}

.ehc-menu .v-card span {
  font-size: 16;
  font-family: "Sailec", "sans-serif";
}

.ehc-menu .v-card {
  margin: 0px 10px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #f4f4f4 !important;
  height: 75px !important;
}

/* .ehc-menu  .v-card {
    border-bottom: 1px solid #131212e0 !important;
} */

/* .toolbar {
    margin:20px 0px !important;
 } */
</style>
