<template>
    <div class="splashScreen">

        <component-bg-image class="splashScreenImg" />

        <div class="splashScreenBottom">
            <div class="splashScreenTagline">
                Your stay, starts here
            </div>
            <div class="splashScreenWelcome" v-if="welcomeText">
                {{welcomeText}}
            </div>
            <div class="splashScreenWelcome" v-else-if="city">
                Welcome to {{city}}
            </div>
            <div class="splashScreenWelcome" v-else>
                Welcome
            </div>
            <div class="splashScreenButtom">
                <ehc-button-small @submit="handleEnter()">
                    Enter&nbsp; >
                </ehc-button-small>
            </div>
        </div>
    </div>
</template>

<script>
    // the position value in v-img is the same as "background-position" as described here 
    // https://developer.mozilla.org/en-US/docs/Web/CSS/background-position
    // bgFixed triggers the css in the style section bellow

import componentBgImage from './component-bg-image.vue'

    export default {
        mixins: [],
        props: ["city", "welcomeText", "userIsAuthenticated"],
        components: {
            componentBgImage
        },
        data: () => ({
        }),
        watch: {
        },
        created(){
        },
        computed: {
        },
        methods: {
            handleEnter(){
                console.log("enter pressed")
                this.$emit('enter')
            },
        },
    }
</script>

<style>

.splashScreen {
    /* make zindex above everything else */
    z-index: 1000;
}

/* I need to make .splashScreenBottom float over .splashScreenImg towards the bottom I also need splashScreenImage to fade to black towards the bottom */
.splashScreenImg {
    /* make it absolute possition */
    position: absolute;
    /* make it fill the whole screen */
    width: 100%;
    height: 100%;
    /* make it fade to black */
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
}

.splashScreenImg .bgFixed {
    /* make it absolute possition */
    position: absolute;
    /* make it fill the whole screen */
    width: 100%;
    height: 100%;
    /* make it fixed */
    background-attachment: fixed;
}

.splashScreenBottom {
    /* make text white */
    color: white;
    /* make it absolute possition */
    position: absolute;
    /* make it show at the bottom of the screen */
    bottom: 0;
    /* make it fill the whole screen */

    width: 100%;
    height: 100%;
    /* make it fade to black */
    /*  */
    background: linear-gradient(
        0deg, 
        black 20%, 
        transparent 100%
        );
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    /* margin-bottom: -4rem; */
    /* align contents to the bottom */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


.splashScreenTagline {
    /* make it bold */
    font-weight: medium;
    /* make it size 52 */
    font-size: 24px;
    margin-bottom: .5rem;
}

.splashScreenWelcome {
    /* make it bold */
    font-weight: bold;
    /* make it size 52 */
    font-size: 52px;
    /* make the space between lines smaller */
    line-height: 1.1;
}

.splashScreen button{
    margin-top: 1.5rem;
    width: 100%;
    height: 75px !important;
}

.splashScreen button .v-btn__content{
    font-size: 18px;
    font-weight: bold;
}


</style>


