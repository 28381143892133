<template>
  <div>
    <ehc-white-label-footer
      :companyLabel="companyLabel"
      :companyImage="companyImage"
      :companyUrl="companyUrl"
      :companyImageInfo="companyImageInfo"
    />

    <v-bottom-navigation
      :value="value"
      background-color="black2"
      dense
      fixed
      app
      height="60px"
      grow
      class="pl-5 pr-5 mt-10"
    >
      <template v-for="link in links">
        <v-btn
          height="100%"
          width="100px"
          :color="
            link.routeName === $route.name ? 'rgb(255,255,255, .1)' : 'black2'
          "
          @click="goToItemRoute(link)"
          x-large
          :key="link.label"
          v-if="showLink(link)"
        >
          <span v-if="showIconText">{{ link.label }}</span>

          <img
            v-bind:src="require('@/assets/icons/' + link.customIcon)"
            height="40px"
            v-if="link.customIcon"
          />
          <v-icon :color="link.iconColor" v-if="link.icon">
            {{ link.icon }}
          </v-icon>
        </v-btn>
      </template>
    </v-bottom-navigation>
  </div>
</template>

<script>
import mixins from "@/mixins";
import ehcWhiteLabelFooter from "./ehc-white-label-footer.vue";

export default {
  components: { ehcWhiteLabelFooter },
  mixins: [mixins],
  data() {
    return {
      test: "https://picsum.photos/350/165?random",
      value: 1,
      showIconText: false,

      links: [
        {
          label: "Home",
          routeName: "Home",
          customIcon: "Home@3x.svg",
          iconColor: "blue",
          showOnly: null,
        },
        {
          label: "Deals",
          routeName: "Deals",
          customIcon: "Deals_footer@3x.svg",
          iconColor: "green",
          showOnly: null,
          alert: "Deals Are Coming Soon",
        },
        {
          label: "Contact",
          routeName: "Contact",
          customIcon: "Contact@3x.svg",
          iconColor: "red",
          showOnly: null,
        },
        // {
        //   label: "Signin",
        //   routeName: "Signin",
        //   icon: "mdi-login",
        //   iconColor: "yellow",
        //   showOnly: "NotAuthenticated"
        // },
        // {
        //   label: "Signout",
        //   routeName: "Signout",
        //   icon: "mdi-logout",
        //   iconColor: "orange",
        //   showOnly: "Authenticated"
        // },
      ],
    };
  },
  methods: {
    showSignin: function () {
      alert("?");
      this.$store.setShowSignin("true");
    },
    showLink: function (link) {
      // Uncomment to deactivate back button on guest info form
      // if ( this.isGuestForm ) { return false }
      if (!link.showOnly) {
        return true;
      }
      if (link.showOnly === "NotAuthenticated" && !this.userIsAuthenticated) {
        return true;
      }
      if (link.showOnly === "Authenticated" && this.userIsAuthenticated) {
        return true;
      }
      return false;
    },
    goToItemRoute: function (item) {
      if (item.alert) {
        this.$store.commit("setShowAlert", true);
        this.$store.commit("setAlertMessage", item.alert);
        return;
      }
      this.goToRoute(item.routeName);
    },
  },
  computed: {
    propertyId: function () {
      return this.$store.getters.propertyId;
    },
    property: function () {
      return this.$store.getters.property;
    },
    linksFiltered: function () {
      this.links.filter(() => el.hide);
    },
    isGuestForm: function () {
      return this.$route.name === "GuestInfo";
    },
    organization: function () {
      return this.$store.getters.organization;
    },
    companyLabel: function () {
      if (this.property.companyLabel) {
        return this.property.companyLabel;
      } else if (this.organization.companyLabel) {
        return this.organization.companyLabel;
      } else if (
        !this.organization.companyImage &&
        !this.property.companyImage
      ) {
        return "Powered by everhost.io.";
      }
      // let CustomLabel = this.property.companyLabel || this.organization.companyLabel || "Powered by Everhost"
      // if ( this.companyImage && !this.companyLabel ) {
      //   CustomLabel = null
      // }
      // if ( this.organization.companyImage && !this.organization.companyLabel ) {
      //   CustomLabel = null
      // }
      // return CustomLabel
      // need to fix which org object to use
    },
    companyImage: function () {
      return this.property.companyImage || this.organization.companyImage || "";
      // need to fix which org object to use
    },
    companyUrl: function () {
      return this.property.companyUrl || null;
      // need to fix which org object to use
    },
    companyImageInfo: function () {
      return this.property.companyImageInfo || null;
      // need to fix which org object to use
    },
  },
};
</script>

<style>
.brand {
  background-color: white;
  color: black;
  opacity: 0.6;
  margin: -30px auto;
  border-radius: 10px;
  max-width: 400px;
}

@media (max-width: 1200px) {
  .brand {
    max-width: 300px;
  }

  @media (max-width: 600px) {
    .brand {
      max-width: 200px;
    }
  }
}

/* .v-bottom-navigation .v-btn{
  height: 70px!important;
} */

/* .theme--light.v-bottom-navigation .v-btn .v-btn__content .v-icon {margin-bottom:5px;}
.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
	color: rgba(0, 0, 0, 0.4) !important;
} */
</style>
