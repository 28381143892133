<!-- This is a work-around for the img tag not working with dynamic icons src -->
<!-- update: looks like require() fixed the issue -->

<template>
  <div>
    <!-- <img :alt="item.title" :src="iconSrc" /> -->

    <img
      v-if="item.icon === 'property-info.png'"
      :alt="item.title"
      src="@/assets/icons/property-info.png"
    />

    <img
      v-if="item.icon === 'area-guide.svg'"
      :alt="item.title"
      src="@/assets/icons/area-guide.svg"
    />

    <img
      v-if="item.icon === 'nearby.svg'"
      :alt="item.title"
      src="@/assets/icons/nearby.svg"
    />

    <img
      v-if="item.icon === 'book-again.svg'"
      :alt="item.title"
      src="@/assets/icons/book-again.svg"
    />

    <img
      v-if="item.icon === 'contact-us.svg'"
      :alt="item.title"
      src="@/assets/icons/contact-us.svg"
    />
  </div>
</template>

<script>
export default {
  name: "ehcItemIcon",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    iconSrc() {
      try {
        return require(`@/assets/icons/${this.item.icon}`);
      } catch (e) {
        console.error(`Icon not found: ${this.item.icon}`);
        return "";
      }
    },
  },
};
</script>
