<!-- 
    TODO: It currently only masks and validates if required, make it validate that the number is the proper length. 
    TODO: needs to validate international phone numbers.
        maybe use this https://louismazel.github.io/maz-ui/documentation/maz-phone-number-input

        no use this https://vuejsexamples.com/international-telephone-input-with-vuetify/
    // uses vue-the-mask https://vuejs-tips.github.io/vue-the-mask/ for masking
 -->

<template>

    <v-text-field   v-mask="'(###) ###-####'"
                    :rules="rules(props)"
                    type="tel"
                    masked="false"
                    outlined 
                    :color="color" 
                    v-bind="props" 
                    v-model="phoneNumber" 
                    @blur="$emit('blur', dePhoneMask(phoneNumber))"
                    >
                    </v-text-field>
</template>

<script>

  export default {
    mixins: [],
    props: ['props', 'value', 'color'],
    components:{},
    data: () => ({
        phoneNumber: ""
    }),
    created () {
        this.phoneNumber = this.value
    },  
    watch: {
        value(val) {
            if (this.dePhoneMask(this.phoneNumber) != val) {
                this.phoneNumber = this.value
            }
        },
        phoneNumber(val) {
            this.$emit('demaskedOut', this.dePhoneMask(val))
        }
    },
    computed: {

    },
    methods: {
        dePhoneMask(phone) {
            //I couldnt get it to return unmasked value other than doing this, It's janky but I'm out of time. 
            if (phone) {
                let newPhone = phone.replace("(", "")
                newPhone = newPhone.replace(") ", "")
                newPhone = newPhone.replace("-", "")
                console.log("dePhoneMask ", newPhone)
                return newPhone
            }
            return null
        },
        rules(field) {
            let rule = []
            if (field.required) { rule.push(v => !!v || 'required')}
            return rule
        },
    },
  }
</script>

<style scoped>
</style>


