import Firebase from 'firebase'

export default {
  data() {
      return {
        maxFavorites: 3,
        addANewPlaceButtonText: "ADD A NEW NEARYBY PLACE",
      }
  },
  created () {
  },
  computed: {
    nearbyCardsArray: function() {
      return Object.keys(this.nearbyCards)
    },
    prefetchedNearby: function(){
      return this.$store.getters.preFetchNearby
    },
    nearbyCards: function(){
      let nearbyCards = this.$store.getters.nearbyCards

      return nearbyCards
    },

    //nearby places array sorted for display
    nearbyFavs(){
      if ( !this.property.nearbyFavs ) return []
      return this.property.nearbyFavs
      .filter(n => n.category === this.selCat.category)
      .map(n => {
        if ( n.hostPick ) {
          n.sortOrder = 0
        } else {
          n.sortOrder = 1
        }
        return n
      })
      .sort( (left, right) => left.sortOrder - right.sortOrder )
    },

    nearbyFavsFiltered() {
      let result = true
      return this.nearbyFavs.filter( el => {
        if ( el.category === this.selCat.category ) {
          result = true
        } else {
          result = false
        }
        return result
      })
      .filter( item => item.hostHide !== true )      
    },

    nearbyFavsFilteredX(){
      let nearbyFavsFiltered = []
      this.nearbyFavs.forEach( el => {
        if ( !el.hostHidden ) {
          if ( el.hostFav ) nearbyFavsFiltered.push(el)
          if ( el.hostPlace ) nearbyFavsFiltered.push(el)
        }
      })
      return nearbyFavsFiltered
    },

    //nearby places that are host picks
    nearbyHostPicks: function(){
      // if ( !this.nearbyFavs ) return []
      //length of nearbyFavs array
      let picks = this.nearbyFavs.filter( el => el.hostPick && !el.hostHide )
      return picks
    },

    nearbyHostHide: function(){
      let nearbyHostHide = this.nearbyFavs.filter( el => el.hostHide )
      return nearbyHostHide
    },

    //status object that controls the display of the nearby places actions
    nearbyFavsStatus: function(){
      let status = {}
      status.count = this.nearbyHostPicks.length
      status.max = this.maxFavorites
      status.over = status.length > status.max
      status.under = status.length < status.max
      status.full = status.count >= status.max
      return status
    },

    property() {
      return this.$store.getters.property
    },
    selCat: function(){
      return this.$store.getters.selCat
    },  
    nearbyLength: function() {
      return Object.keys(this.nearbyCards[this.selCat.category].array).length - 1
    },
    // nearbyHostPicksCount: function(){
    //   return this.nearbyHostPicks.length
    // },
    // nearbyFavsCount: function(){
    //   return Object.keys(this.nearbyFavs).length
    // },
    // nearbyHostPicksFull: function(){
    //   return this.nearbyHostPicksCount >= this.maxFavorites ? true : false
    // }, 



  },
  methods: {

    getNearbyCards(){
      this.$store.dispatch('getNearbyCards')
    },

    loadNearbyCardsIntoFBDefaults() {
      // console.log("loadNearbyCardsIntoFBDefaults")
      let nearbyCardsArr = Object.keys(this.$store.getters.nearbyCards).map( (key) => {
        let card = {}
        card.category = this.$store.getters.nearbyCards[key].category
        card.description = this.$store.getters.nearbyCards[key].description
        card.keyword = this.$store.getters.nearbyCards[key].description
        card.radius = this.$store.getters.nearbyCards[key].radius
        return card
      })
      // console.log("nearbyCardsArr",nearbyCardsArr)
      let nearbyCardsObj = {...nearbyCardsArr}
      Firebase.firestore().collection("defaults").doc("nearby").set(nearbyCardsObj)
      .then( () => {
        // console.log("loadNearbyCardsIntoFBDefaults success")
      })
      .catch( (error) => {
        // console.log("loadNearbyCardsIntoFBDefaults error", error)
      })
    },

    //Calls the getNearby firebase function - should be put in the store so it can be called anywhere (if location - latLng is available)
    prefetchNearby: function( propertyId, category ){
      return null
      this.getLocation(this.property).then((location) => {
        let payload = {}
        payload.location = location
        payload.propertyId = propertyId
        if ( category ) {payload.keywords = [category]}
        // console.log('prefetchNearbyObj ', payload)
        let getNearby = Firebase.functions().httpsCallable('getNearby')
        getNearby(payload)
        .then(() => {
          // console.log("prefetchNearby done")
        })
        .catch(err => {
          console.log("prefetchNearby err: ", err)
        })
      })
    },

    //used to get the latLng based on city, state or location from the mapaddress array in the property doc
    async getLocation(property){
      return new Promise((resolve, reject) => {
          let location
          if ( property.mapAddress ) {
            // console.log("there is a mapAddress")
            location = `${property.mapAddress.lat},${property.mapAddress.lng}` 
            resolve(location)
          } else {
            // console.log("there is not a mapAddress")
            let service = new window.google.maps.Geocoder
            let obj = { address: `${property.city}, ${property.state}` }
            service.geocode(obj, function(res, status){
                  if (status == "OK") { 
                    let loc = res[0].geometry.location
                    let lat = loc.lat()
                    let lng = loc.lng()
                    // console.log(`${lat},${lng}`)
                    location = `${lat},${lng}`
                    // console.log("getLocation resolving", location)
                    resolve(location)
                  } else {
                    // console.log("getLocation failed ",status)
                    reject(status)
                  }
            })
          }
      })
    },  

    async clearNearbyPrefetched(propertyId){
      // console.log("clearNearbyPrefetched")
      return await Firebase.functions().httpsCallable('deletePropertyNearby')
      .then(() => {
        // console.log("clearNearbyPrefetched done")
      })      
    },

    isHostHide(place_id){
      try {
        let hiddenFav = false
        // check for a hostHidden = true
        this.property.nearbyFavs.forEach( el => {
          if ( el.place_id === place_id && el.hostHide ) hiddenFav = true
        })
        // override if there is a !hostPick on the same place_id
        this.property.nearbyFavs.forEach( el => {
          // check for a hostHidden = true
          if ( el.place_id === place_id && !el.hostHide ) hiddenFav = false
        })
        return hiddenFav
      } catch (error) {
        return false
      }
    },

    //used by various nearby methods
    $hostPlaceAlreadyExists(item){
      let result = false
      if ( this.property.nearbyFavs ) {
        this.nearbyFavs.forEach( (fav) => {
          if ( fav.place_id === item.place_id && !fav.hostHide ) {
            this.$store.commit('setAlertMessage', `${item.name} already exists in your list of host selected places.`)
            this.$store.commit('setShowAlert', true)        
            result = true
          }
        })
      }
      return result
    },


  }
}