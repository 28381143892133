<!-- example usage
    <ehc-card-toolbar 
        title="whatever you want the title to be"
        subtitle="whatever you want the subtitle to be"
        edit
        close
        justify="left" //(or "center")
        ></ehc-card-toolbar> 
-->

<template>
  <div :class="`ehc-toolbar-wrapper mt-16 ${fatClass()}`">
    <div class="ehc-toolbar mx-auto mt-n13">
      <div class="ehc-toolbar-contents">
        <slot></slot>
        <div :class="`ehc-toolbar-title ${titleClass}`">
          <span v-if="title" class="notranslate">{{ title }}</span
          ><br v-if="title" />
          <!-- <span v-if="subtitle" class="text-subtitle-1">{{subtitle}} </span>          -->
          <v-chip v-if="isBeta" class="ma-2" color="green" text-color="white">
            Beta!
          </v-chip>
        </div>
        <!-- <slot></slot> 
        <v-toolbar-title :class="titleClass" >
            <span v-if="title" class="text-h5">{{title}}</span><br v-if="title"/>
            <span v-if="subtitle" class="text-subtitle-1">{{subtitle}} </span>
            

        </v-toolbar-title> -->
        <v-spacer v-if="justify == 'left'"></v-spacer>
        <!-- <img src="@/assets/icons/Close@3x.svg" v-if="close && userIsAuthenticated" @click="$emit('close')">
        <slot name="right"></slot> -->
        <div :class="`ehc-toolbar-subtitle`" v-if="fat || fatBottom">
          <slot name="subtitle"></slot>
        </div>
        <ehc-button-small
          v-if="edit && userIsAuthenticated"
          class="mt-2"
          @submit="handleEdit()"
          text="EDIT"
          icon="editdark.svg"
          invert
        >
        </ehc-button-small>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  mixins: [mixins],
  props: {
    fat: { type: Boolean, default: false },
    fatBottom: { type: Boolean, default: false },
    title: String,
    subtitle: String,
    justify: { type: String, default: "center" },
    close: { type: Boolean, default: false }, //if true adds a close button
    edit: { type: Boolean, default: false }, //if true adds a edit button,
  },
  components: {},
  data: () => ({
    betaArray: [], //use this array to set sections beta - 'new feature'
  }),
  created() {},
  watch: {},
  computed: {
    titleClass: function () {
      switch (this.justify) {
        case "left":
          return "";
        case "center":
          return "flex text-center";
        default:
          return this.justify;
      }
    },
    isBeta: function () {
      return this.betaArray.includes(this.title);
    },
  },
  methods: {
    handleEdit() {
      console.log("edit");
      this.$emit("edit");
    },
    fatClass() {
      if (this.fat) {
        return "ehc-fat";
      }
      if (this.fatBottom) {
        return "ehc-fat-bottom";
      }
    },
  },
};
</script>

<style scoped>
.ehc-toolbar-title {
  font-weight: 400;
  color: #1d3557;
  font-size: 22px;
}
.ehc-toolbar-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #1d3557;
}

.ehc-toolbar {
  z-index: 2;
  box-shadow: -10px 10px 50px rgb(18 31 50 / 15%) !important;
  max-width: 485px;
  min-width: 330px;
  border-radius: 4px !important;
  background-color: #ffffff;
  padding-top: 23px;
  padding-bottom: 23px;
}

.ehc-toolbar-wrapper {
  display: flex;
  width: 100%;
  position: relative;
  /* set background clear */
}

.ehc-fat .ehc-toolbar-contents {
  /* height: 64px; */
}

/* TODO: GTA - is there a better way to move this card down per ed's specs */
.ehc-fat {
  margin-top: 80px !important;
  margin-bottom: 20px !important;
}

.ehc-fat-bottom {
  margin-bottom: 50px !important;
}
</style>
