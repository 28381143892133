// import ehcWhiteLabelFooter from './ehc-white-label-footer.vue'
// components: { ehcWhiteLabelFooter },
// example: <ehc-white-label-footer :companyLabel="companyLabel" :companyImage="companyImage" :companyUrl="companyUrl"></ehc-white-label-footer>

<template>

<div>
  <!---for company label only, minimum width-->
  <p class="text-center mb-5"
    v-if="!companyImage"
    @click="goToCompanyUrl(companyUrl)"
    >
    <span class="whiteLabel">
      <span v-html="companyLabel" class="text-wrap text-break " />
      <ehc-white-label-footer-btn @editBrand='editBrand' v-if="(orgIsPremium && userIsAuthenticated) || userIsAdmin"/>
    </span>
  </p>

  <!--- for company label and logo combo--->
  <v-card
    v-else
    color="rgb(255, 255, 255, .7)"
    :max-width="cardWidth"
    @click="goToCompanyUrl(companyUrl)"
    :class="whiteLabelClass"
    flat
  >
      <v-card-text v-if="companyLabel" class="text-center py-3 px-2 text-wrap" v-html="companyLabel"></v-card-text>
      <!-- I used img instead of v-img because v-img wouldn't change aspect ratio without refresh -->
      <v-card-text v-if="companyImage" class="text-center mx-auto">
        <img :src="companyImage" color="transparent" class="mx-auto" :width=imgWidth style="max-width:100%" />
      </v-card-text>
      <v-card-actions v-if="(orgIsPremium && userIsAuthenticated) || userIsAdmin" >
      <ehc-white-label-footer-btn @editBrand='editBrand' />
      </v-card-actions>
  </v-card>


<!-- FOR DEBUGGING -->
  <v-card v-if="userIsDev && showCardWidthData" class="mt-5 pa-3" flat>
  <h3>For Debugging white label</h3>
  ImgWidth {{ imgWidth }}<br/>
  <!-- ImgWidth2 {{ imgWidth2 }}<br/> -->
  CardWidth {{ cardWidth }}<br/>
  ScreenWidth {{ screenWidth }}<br/>
  LabelLength {{ companyLabel.length }}<br/>
  isMobileScreen {{isMobileScreen}}<br/>
  isMobile {{isMobile}}
  <v-text-field label="CompanyLabelFactor" v-model="companyLabelFactor" /><br/>
  <v-btn @click="visitUrl(companyImage)" class="pointer">Image</v-btn>
  <pre>{{companyImageInfo}}</pre>
  </v-card>

  <!---invisible card is added so content is displayed above footer-->
  <v-card class="spacer">
    <v-card-text>spacer</v-card-text>
    <v-card-text>spacer</v-card-text>
    <v-card-text>spacer</v-card-text>
  </v-card>

  
</div>
</template>

<script>
import mixins from '@/mixins'
import ehcWhiteLabelFooterBtn from './ehc-white-label-footer-btn.vue'

export default {
  mixins: [mixins],
  components: { ehcWhiteLabelFooterBtn },
  props: {
    companyLabel: {
      type: String,
      default: "",
    },
    companyImage: {
      type: String,
      default: null,
    },
    companyUrl: {
      type: String,
      // default: 'https://everhost.io',
    },
    companyImageInfo: {
      type: Object,
    }
  },
  data() {
    return {
      aspectRatio: null,
      companyLabelFactor: 14,
      mobileWidth: 300,
      desktopWidth: 400,
      showCardWidthData: false,
      screenWidth: window.innerWidth, // Initial value
    }
  },
  methods: {
    goToCompanyUrl(companyUrl) {  
      if (companyUrl) {
        window.open(companyUrl, "_blank");
      }
    },
    editBrand() {
      this.$router.push({
        name: "PropertyNew",
        query: {
          showPremiumOnly: true,
        },
      });
    },
    calculateCardWidth: function(companyImageWidth, companyLabelLength, isMobile, mobileWidth, desktopWidth, companyLabelFactor) {
      const a = companyImageWidth + 50;
      const b = companyLabelLength * companyLabelFactor;
      const c = isMobile ? mobileWidth + 50 : desktopWidth;
      const cardWidth = this.maxTwoNumbersNotMoreThanThird(a, b, c);
      return `${cardWidth}px`;
    },
    maxTwoNumbersNotMoreThanThird: function(a, b, c) {
      return Math.min(Math.max(a, b), c);
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  computed: {
    whiteLabelClass(){
      let className = "mx-auto pa-0 rounded-xl text-center text-wrap text-break whiteCard"
      if (this.companyUrl) {
        return className += " pointer";
      } else {
        return className += " nopointer";
      }
    },
    // width: function(){ 
    //   try {
    //     if ( this.companyImageInfo.width < 300 && !this.companyLabel ) {
    //       return `${this.companyImageInfo.width + 50}px`
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    //   if ( this.isMobile ) {
    //     return `${this.mobileWidth}px}`
    //   }
    //   return `${this.desktopWidth}px`
    // },
    // screenWidth: function(){
    //   return window.innerWidth
    // },
    // imgWidth: function(){
    //   try {
    //     return this.companyImageInfo.width 
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }, 

    imgWidth: function(){
      try {
        return this.screenWidth < this.companyImageInfo.width ? this.screenWidth - 50 : this.companyImageInfo.width 
      } catch (error) {
        console.log("something went wrong with imgWidth")
      }
    }, 
    cardWidth: function(){ 
      if (!this.companyImageInfo) {
        return this.isMobileScreen ? `${this.mobileWidth}px` : `${this.desktopWidth}px`
      }
      return this.calculateCardWidth(this.companyImageInfo.width, this.companyLabel.length, this.isMobile, this.mobileWidth, this.desktopWidth, this.companyLabelFactor);
    },
    isMobileScreen: function() {
      return this.screenWidth < 375;
    },
  },
  created(){
    let showCardWidthData = sessionStorage.getItem('showCardWidthData');
    if (showCardWidthData) {
      this.showCardWidthData = true
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidth);
    // if browser session has a value for showCardWidthData, use that value
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
 }  
</script>

<style scoped>
.whiteCard .v-card__text {
  font-family: sailec !important;
  font-size: 1.2rem !important;
  font-weight: normal !important;
  color: #47383D !important;
  white-space: pre;
  overflow: hidden;
}


.whiteLabel {
  display: inline-block;
  max-width:300px;
  background-color: white;
  opacity: .6;
  color:black;
  margin: -20px auto;
  padding: 2px 10px 2px 10px !important;
  border-radius: 20px;
  text-align: center;
  white-space: pre;
  overflow: hidden;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.nopointer {
  cursor: default !important;
} 

.spacer {
  opacity: 0;
}

@media (min-width: 1200px) {

  .whiteLabel {
    max-width: 400px;
    padding: 2px 15px 2px 15px !important;
  }
}

</style>