import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import firebase from 'firebase'
import Auth from './auth'
// import ActionsImages from "./actions-images"
import Weather from "./weather"
import Images from "./images"
import Nearby from "./nearby"
import Carditems from "./carditems"
import BackgroundImages from "./backgroundImages"
import DefaultProperty from "./defaultProperty.json"
import { compileToFunctions } from 'vue-template-compiler'

Vue.use(Vuex)

let makeRandomId = function(test = "true"){
  let randomId = Math.floor(1000000 + Math.random() * 9000000)
  return randomId.toString()
}

let fixNearbyCategories = function(property){
  //loop property.nearbyCategories and make sure there is a hostHides Array
  try {
    let nearbyCategories = property.nearbyCategories
    let newNearbyCategories = []
    nearbyCategories.forEach( (item) => {
      if ( !item.hostHides ) { item.hostHides = [] }
      if ( !item.hostPicks ) { item.hostPicks = [] }
      if ( !item.category ) { item.category = item.keyword }
      newNearbyCategories.push(item)
    })
    property.nearbyCategories = newNearbyCategories
  } catch (e) { console.log("fixNearbyCategories error",e) }
}

function setDefaultValues(obj) {
  obj.noguestinfo = obj.noguestinfo === undefined ? true : obj.noguestinfo
  obj.showName = obj.showName === undefined ? true : obj.showName
  obj.showAddress = obj.showAddress === undefined ? false : obj.showAddress
  obj.showSplashScreen = obj.showSplashScreen === undefined ? false : obj.showSplashScreen
}



export default new Vuex.Store({
  state: {
    //property
    propertyId: null,
    property: {},
    properties: [],
    defaultProperty: DefaultProperty,
    defaultPropertyId: 'default12821', // this is the propertyI used to make new properties

    //organization info
    organization: {},

    //alert
    alert: {
      show: false,
      message: null,
    },
    showConfirm: false,
    confirmMessage: null,

    //backgrounds

    //app wide
    error: "",
    loading: false,
    devMode: false,
    host: window.location.host,
    baseUrl: "https://everhost.io/",

    //images
    images: [],
    companyImageInfo: null,

    //propertyInfo
    subsections: [],
    subsection: null,

    //areaGuide
    areaGuide: [],

    //admin only?
    instructions: [],
    adminDrawer: null,
    showSignin: false,

    //popup/dialogbox controls
    popup: false,

    //settings
    appSettings: null,
  },
  getters: {
    //property
    property: state => state.property,
    propertyId: state => state.propertyId,
    properties: state => state.properties,
    defaultProperty: state => state.defaultProperty,
    defaultPropertyId: state => state.defaultPropertyId,

    //company info
    organization: state => state.organization,

    //alert
    showAlert: state => state.alert.show,
    alertMessage: state => state.alert.message,
    showConfirm: state => state.showConfirm,
    confirmMessage: state => state.confirmMessage,

    //backgrounds

    //app wide
    host: state => state.host,
    error: state => state.error,
    loading: state => state.loading,
    devMode: state => state.devMode,
    baseUrl: state => state.baseUrl,

    //admin only?
    instructions: state => state.instructions,
    instruction: state => docId => {
      state.instructions.filter( (el) => el.docId === docId )
    },
    adminDrawer: state => state.adminDrawer,
    showSignin: state => state.showSignin,

    //images
    images: state => state.image,
    companyImageInfo: state => state.companyImageInfo,

    //propertyInfo
    subSections: state => state.subsections.filter( el => !el.deletedAt ), //need to fix camel case uses for this
    subsections: state => state.subsections.filter( el => !el.deletedAt ),
    subsection: state => state.subsection,

    //areaGuide
    areaGuide: state => state.areaGuide.filter( el => !el.deletedAt ),

    //popup/dialogbox controls
    popup: state => state.popup,

    //settings
    appSettings: state => state.appSettings,

  },
  mutations: {
    setPropertyId (state,payload) {
      state.propertyId = payload
    },
    setProperty (state,payload) {
      // console.log("setProperty",payload)
      state.property = payload
    },
    updateProperty (state, payload) {
      for (var key in payload) {
        state.property[key] = payload[key]
      }
    },
    setOrganization (state,payload) {
      state.organization = payload
    },
    setShowAlert (state,payload){
      state.alert.show = payload
    },
    setAlertMessage (state,payload){
      state.alert.message = payload
    },
    setShowConfirm (state,payload){
      state.showConfirm = payload
    },
    setConfirmMessage (state,payload){
      state.confirmMessage = payload
    },
    setProperties (state,payload) {
      state.properties = payload
    },
    setInstructions (state,payload) {
      state.instructions = payload
    },
    setImages (state,payload) {
      state.images = payload
    },
    setError (state,payload) {
      state.error = payload
    },
    setLoading (state,payload ) {
      state.loading = payload
    },
    setSubsections (state,payload){
      state.subsections = payload
    },
    addToSubsections ( state,payload ){
      state.subsections = [...state.subsections,...payload]
    },
    setSubsection ( state, payload) {
      state.subsection = payload
    },
    setSections (state,payload){
      //console.log"setSections",payload)
      if ( payload.type == "propertyinfo" ) {
        state.subsections = payload.sections }
      else if ( payload.type == "areaguide" ) {
        state.areaGuide = payload.sections }
    },
    setAreaGuide ( state, payload) {
      state.areaGuide = payload
    },
    setTemporaryBackground (state, payload){
      state.temporaryBackground = payload
    },
    clearError ( state ) {
      state.error = ""
    },
    setWeatherObj (state,payload) {
      state.weatherObj = payload
    },  
    setPopup (state,payload){
      state.popup = payload
    },
    setCompanyImageInfo (state,payload){
      state.companyImageInfo = payload
    },
    setAppSettings (state,payload){
      state.appSettings = payload
    },

  },
  actions: {
    getAreaGuide(context){
      let areaGuide = []
      const areaGuideRef = firebase.firestore().collection("defaults").doc("areaguide").collection("subsections")
      areaGuideRef.get()
      .then( (docs) =>{
        docs.forEach( (doc) => {
          let obj = doc.data()
          areaGuide.push(obj)
        })
        // console.table(areaGuide)
        context.commit("setAreaGuide",areaGuide) 
      }) 
      .catch ( (err) => console.log(err) )
    },
    addCustomSubsection(context, propertyId){
      let obj = {
        9: {
          type: "propertyinfo",
          dociId: "new section",
          pageTitle: "New Section",
          label: "New Section",
          sortOrder: "2"
        }
      }
      const propertySubsectionsRef = firebase.firestore().collection("properties").doc(propertyId).collection(obj[9].type).doc('subsections')
      propertySubsectionsRef.update(obj, { merge: true })
      .catch( err => console.log(err) )
    },
    getCustomSubsections(context,payload){
      return new Promise((resolve, reject) => {
        let sections = []
        let type = payload.type
        let propertyId = context.getters.propertyId
        if ( payload.propertyId ) { propertyId = payload.propertyId }
        const propertySubsectionsRef = firebase.firestore().collection("properties").doc(propertyId).collection(type).doc('subsections')
        propertySubsectionsRef.get()
        .then( (doc) => {
          let obj = doc.data()
          Object.keys(obj).forEach(key => {
          //we need to make sure there is a deletAd and hiddenAt field that defaults to null
          if ( !obj[key].deletedAt ) { obj[key].deletedAt = null }
          if ( !obj[key].hiddenAt ) { obj[key].hiddenAt = null }
          sections.push(obj[key])
          })      
          if ( type === "propertyinfo" ) { context.commit('setSubsections',sections) }
          if ( type === "areaguide" ) { context.commit('setAreaGuide',sections) }
          resolve(doc)
        })
        .catch ( (err) => console.log(err) )
      })
    },

    // getCustomSubsections2(context,payload){
    //   let type = payload.type
    //   let propertyId = payload.propertyId

    //   const propertySubsectionsRef = firebase.firestore().collection("properties").doc(propertyId).collection(type).doc('subsections')
    //   propertySubsectionsRef.get()
    //   .then( (doc) => {
    //     let sections = []
    //     let obj = doc.data()
    //     Object.keys(obj).forEach(key => {
    //     sections.push(obj[key])
    //     })      
    //     if ( type === "propertyinfo" ) { context.commit('setPropertyinfo',sections) }
    //     if ( type === "areaguide" ) { context.commit('setAreaguide',sections) }
    //   })
    //   .catch ( (err) => console.log(err) )
    //   },

    //type is "propertyinfo or areaguide"
    //field would be "deletedAt" or "hiddenAt"
    //docId is the doc id in property document
    async markCustomSectionDeletedAtOrHiddenAt(context,payload){
      const { type,docId,field } = payload
      const propertyId = context.getters.propertyId
      let array = []
      if ( type === "propertyinfo" ) { array = context.getters.subsections }
      if ( type === "areaguide" ) { array = context.getters.areaGuide }
      let newArray = array.map( el => {
        if ( el.docId === docId ) { el[field] = Date.now() }
        return el
      })
      const propertySubsectionsRef = firebase.firestore().collection("properties").doc(propertyId).collection(type).doc('subsections')
      let newObj = {...newArray}
      // console.log(("mark deleted", newObj)
      return await propertySubsectionsRef.set(newObj)
      .then(() => {
        //if this is the default property, remove this section in the default sections as well
        if ( propertyId === context.getters.defaultPropertyId ) {
          context.dispatch("deleteDefaultSection",payload)          
        }
        ///Put an if statement here to delete the default section
        // if (?)    {deleteDefaultSection()}    
        // console.log((`${docId} ${field} was set on ${propertyId}`)
        return true
      }).catch( err => {throw err} )
    },
    //type is "propertyinfo or areaguide"
    //field would be "deletedAt" or "hidenAt"
    //docId is the doc id in property document    

    deleteDefaultSection(context, payload){
      const { type,docId } = payload
      // console.log(("deleteDefaultSection", type,docId)
      const defaultsRef = firebase.firestore().collection("defaults")
      const defaultSubsectionsRef = defaultsRef.doc(type).collection("subsections")
      const defaultSubsectionRef = defaultSubsectionsRef.doc(docId)
      defaultSubsectionRef.delete().then( console.log(`${docId} deleted from ${type} defaults`))
    },

    async renameSubsection(context,payload){
      const { type,docId,name } = payload
      const propertyId = context.getters.propertyId
      let array = []
      if ( type === "propertyinfo" ) { array = context.getters.subsections }
      if ( type === "areaguide" ) { array = context.getters.areaGuide }
      let newArray = array.map( el => {
        if ( el.docId === docId ) { 
          el['label'] = name
          el['pageTitle'] = name
          el['tabLabel'] = name
        }
        return el
      })
      const propertySubsectionsRef = firebase.firestore().collection("properties").doc(propertyId).collection(type).doc('subsections')
      let newObj = {...newArray}
      return await propertySubsectionsRef.set(newObj)
      .then(() => {
        // console.log((`${docId}  was renamed to ${name} on ${propertyId}`)
        return true
      }).catch( err => {throw err} )
    },    
    moveInCustomSection(context,payload) {
      // console.log((payload)
      let { type,item,direction } = payload
      let arr
      // console.log((type)
      if ( type === "propertyinfo" ) { arr = context.getters.subsections }
      if ( type === "areaguide" ) { arr = context.getters.areaGuide }
      let i = arr.indexOf(item)
      let from = arr.indexOf(item)
      let to

      // console.log((i,"of",arr.length, "move", direction)

      //return if trying to move first lower or last higher 
      if ( !i && direction === 'up' ) { return }
      if ( i === arr.length-1 && direction == 'down' ) { return }
      if ( direction === "up" ) {
          to = from - 1
      } else if ( direction === "down") {
          to = from + 1
      }

      // Make sure a valid array is provided
      if (Object.prototype.toString.call(arr) !== '[object Array]') {
          throw new Error('Please provide a valid array');
      }

      if ( arr.length === 2 ) {
        item = arr.pop()
        // console.log(("popped",item)
        arr = [item,...arr]
        // console.log(("new arr", arr)
      } else {
        // Delete the item from it's current position
        item = arr.splice(from, 1);

        // Make sure there's an item to move
        if (!item.length) {
            throw new Error('There is no item in the array at index ' + from);
        }


        // Move the item to its new position
        arr.splice(to, 0, item[0]);
        
      }

      // console.log(("arr", arr)

      if ( type === "propertyinfo" ) { context.commit("setSubsections",arr) }
      if ( type === "areaguide" ) { context.commit("setAreaGuide",arr) }
  },

  updateSections ( context,payload ){
    let { type,sections } = payload
    let propertyId = context.getters.propertyId
    let sectionRef = firebase.firestore().collection('properties').doc(context.getters.propertyId).collection(type).doc('subsections')
    let obj = {...sections}
    sectionRef.set(obj)
    .then( console.log(`${type} for ${propertyId} has been updated with ${Object.keys(obj).length} items.`))
    .catch( err => console.log)
  },

  //organization info from uid
  getOrganization(context, uid){
    // console.log(("getOrganization",uid)
    let organizationRef = firebase.firestore().collection('organizations').where('members', 'array-contains', uid)
    organizationRef.get()
    .then( (snapshot) => {
      if ( !snapshot.empty ) {
      let organization = snapshot.docs[0].data()
      context.commit("setOrganization",organization) }
      else { console.log("no organization found") }
    }).catch( err => {throw err} )
  },

  //get organization from property field orgId
  getOrganizationFromPropertyOrgId(context, orgId){
    // console.log(("getOrganizationFromPropertyOrgId",orgId)
    let organizationRef = firebase.firestore().collection('organizations').doc(orgId)
    organizationRef.get()
    .then( (doc) => {
      if ( !doc.empty ) {
      let organization = doc.data()
      context.commit("setOrganization",organization) }
      else { console.log("no organization found") }
    }).catch( err => {throw err} )
  },

    ///properties
    //new getProperty() from openAI 
    getProperty(context) {
      return new Promise(async (resolve, reject) => {
        try {
          const propertiesRef = firebase.firestore().collection('properties')
          const propertyId = context.getters.propertyId
          const doc = await propertiesRef.doc(propertyId).get()
          let obj = {
            propertyId: null
          }
          if (doc.exists) {
            obj = doc.data()
            if (obj.hasOwnProperty('searchIndex')) {
              // console.log("searchIndex exists")
              obj.searchIndex = null;
            }
          } 
          setDefaultValues(obj)
          fixNearbyCategories(obj)
          context.commit('setProperty', obj) 
          resolve(doc)
        } catch (error) {
          reject(error)
        }
      })
    },

    // getProperty(context){
    //   return new Promise((resolve, reject) => {
    //     // console.log(("getting property")
    //     const propertiesRef = firebase.firestore().collection('properties')
    //     const propertyId = context.getters.propertyId
    //     propertiesRef.doc(propertyId).get()
    //     .then( (doc) => {
    //         let obj = doc.data()
    //         if ( obj.noguestinfo === undefined ) { obj.noguestinfo = true } //Sets default noguestinfo flag to true - guest info form does NOT show unless user sets field in propertyNew form
    //         if ( obj.showName === undefined ) { obj.showName = true } //Sets default showName flag to true
    //         if ( obj.showAddress === undefined ) { obj.showAddress = false } //Sets default showAddress flag to true
    //         if ( obj.showSplashScreen === undefined ) { obj.showSplashScreen = false } //Sets default showAddress flag to true
    //         fixNearbyCategories(obj)
    //         // console.log(("got property", obj)
    //         this.commit('setProperty', obj) 
    //         resolve(doc)
    //     })
    //   })
    // },

    getProperties(context){
      let properties = []
      const userId = context.getters.user.data.uid
      // console.log(userId)
      const propertiesRef = firebase.firestore().collection('properties')
      propertiesRef.where("uid","==", userId).where("deletedAt", "==", null).get()
      .then( (docs) => {
        // console.log(docs.size)
        let properties = []
        docs.forEach( (doc) => {
          let obj = doc.data()
          properties.push(obj)  
        })
        // console.log("get properties ran; ", properties)
        context.commit("setProperties",properties)  
      })
    },
    makeNewProperty(context){
      // console.log("oncall")
      let uid = context.getters.user.data.uid
      // console.log('makeNewProperty', uid)
      const addProperty = firebase.functions().httpsCallable('makeNewProperty')
      addProperty(uid)
      .then( (res) => {
        // console.log(("makeNewProperty ",res)
      })
    },
    copyProperty(context,propertyId){
      // console.log("copyProperty", propertyId)
      let data = {}
      data.propertyId = propertyId
      // console.log("copyProperty", data)
      const addProperty = firebase.functions().httpsCallable('copyProperty',data)
      addProperty(data)
      .then( (res) => {
        console.log(res)
      })
    },
    makeDefaultProperty(context){
      let obj = context.getters.defaultProperty
      obj.createdAt = Date.now()
      obj.updatedAt = Date.now()
      const propertiesRef = firebase.firestore().collection('properties')
      propertiesRef.doc("default12821").set(obj)
      .then( console.log("made Default Property") )
      .catch( err => console.log(err) )
    },
    subscribeToProperties(context){
      const properties = []
      const propertiesRef = firebase.firestore().collection('properties')
      propertiesRef.where("uid","==", userId).onSnapshot( (docs) => {
        context.commit("setProperties",[])        
        docs.forEach( (doc) => {
          let obj = doc.data()
          properties.push(obj)  
        })
        context.commit("setProperties",properties)  
      })
    },
    //rather not use delete action - mark for deletion instead by setting deletedAt to Date.now()
    deleteProperty(context,payload){
      const propertiesRef = firebase.firestore().collection('properties')
      propertiesRef.doc(payload).delete()
      .then( () => console.log("deleted ", payload))
      .catch( (err) => console.log(err) )
    },
    markPropertyDeletedAt(content,propertyId){
      let obj = {}
      obj.deletedAt = Date.now()
      // console.log('markPropertyDeletedAt',obj)
      const propertyRef = firebase.firestore().collection('properties').doc(propertyId)
      propertyRef.set(obj, { merge:true })
      .then( () => console.log(`${payload} marked deletedAt` ))
      .catch( (err) => console.log(err) )  
    },
    markPropertyPublishedAt(content,payload){
      // console.log("payld",payload)
      let propertyId = payload.propertyId
      let obj = {}
      if ( payload.publishedAt ) { obj.publishedAt = Date.now() } else { obj.publishedAt = null }
      const propertyRef = firebase.firestore().collection('properties').doc(propertyId)
      propertyRef.set(obj, { merge:true })
      .then( () => console.log(`${propertyId} marked published ${payload.publishedAt}` ))
      .catch( (err) => console.log(err) )  
    },
    async updatePropertyInfoContent(context,payload, routeName){
      let obj = {}
      obj[payload.docId] = payload.content
      const propertiesRef = firebase.firestore().collection('properties')
      const propertyRef = propertiesRef.doc(context.getters.propertyId)
      obj.updatedAt = Date.now()
      obj.updatedFrom = "propertyPageContent"
      return await propertyRef.set(obj, { merge: true })
      .then( () => {
        console.log(`${payload.docId} updated`)
      }).then( () => {
        context.dispatch("getProperty" )
        return true    
      }).catch ( err => console.log(err) )  
    },
    async updateProperty(context,payload){
      let obj = {}
      let mergeData = payload.mergeData || false
      obj = payload
      obj.docId = context.getters.propertyId
      obj.updatedAt = Date.now()
      console.log("updateProperty on line 523",obj)
      // if ( obj.mapAddress !== null ) { context.dispatch("clearMapAddress" ) }
      const propertiesRef = firebase.firestore().collection('properties')
      const propertyRef = propertiesRef.doc(obj.docId)
      return await propertyRef.set(obj, { merge: mergeData })
        .then((response) => { 
          console.log(`${obj.docId} updated`)
          context.dispatch("getProperty" )
        }).catch ( err => console.log(err) )  
    },

    //TODO: There should be a better way to replace old mapAddress data
    //this sets mapAddress to [], then updateProperty sets it to the new value
    async clearMapAddress(context){
      // console.log("clearMapAddress")
      let obj = {}
      obj.mapAddress = []
      const propertiesRef = firebase.firestore().collection('properties')
      const propertyRef = propertiesRef.doc(context.getters.propertyId)
      return await propertyRef.set(obj, { merge: true })
      .then( () => {
        console.log(`mapAddress cleared`)
      }).then( () => {
        context.dispatch("getProperty" )
        return true    
      }).catch ( err => console.log(err) )
    },

    async putProperty(context,payload,propertyId){
      let obj = {}
      obj = payload
      obj.docId = context.getters.propertyId
      obj.updatedAt = Date.now()
      const propertiesRef = firebase.firestore().collection('properties')
      const propertyRef = propertiesRef.doc(obj.docId)
      return await propertyRef.set(obj)
      .then((response) => {
        console.log(`${obj.docId} updated`) })
      .catch( (err) => console.log(err) )
    },

    copyPropertyInfo( context, payload){
      // console.log('copyPropertyInfo')
      const defaultPropertyInfoRef = firebase.firestore().collection("defaults")
      defaultPropertyInfoRef.doc("propertyInfo").collection("subsections").get()
      .then ( (docs) => {
        // console.log("docs.size",docs.size)
        docs.forEach( doc => {
          let obj = doc.data()
          // console.log("propinfo ;", obj)
          defaultPropertyInfoRef.doc("propertyinfo").collection("subsections").doc(obj.docId).set(obj)
          .then( console.log("something happened copyPropertyInfo") )
          .catch( err => console.log(err) )
          })
        })
    },
    putNewSection( context,payload ){
      console.log("putNewSection", payload)
      return new Promise((resolve, reject) => {
        const newIndex = context.getters.subsections.length.toString()
        let docId = payload.data.label.toLowerCase().replace(/\s/g, '')
        let type = payload.type
        let obj = {}
        obj = {
            [docId] : {
            label: payload.data.label,
            pageTitle: payload.data.label,
            docId: docId,
            sortOrder: newIndex.toString()
          }
        }
        if (payload.data.link) { obj[docId].link = payload.data.link }
        console.log("putNewSection: ",obj)
        let newSectionRef = firebase.firestore().collection('properties').doc(context.getters.propertyId).collection(payload.type).doc('subsections')
        newSectionRef.set(obj, { merge: true} )
        .then( (res) => {
          // console.log("res: ", res)
          //if this is a new section for the default property, copy it to the defaults/TYPE/subsections
          let defaultPropertyId  = context.getters.defaultPropertyId 
          if ( context.getters.propertyId === defaultPropertyId){
            context.dispatch("updateSectionToDefaults",payload)
          }
          resolve(res)
        }).catch( err => console.log(err) )
      })
    },

    updateSectionToDefaults( context,payload ){
      // console.log("going to updateSectionToDefaults", payload.data)
      const newIndex = context.getters.subsections.length.toString()
      let docId = payload.data.label.toLowerCase().replace(/\s/g, '')
      let type = payload.type
      let obj = {}
      obj = {
          label: payload.data.label,
          pageTitle: payload.data.label,
          docId: docId,
          sortOrder: newIndex.toString()
        }
      // console.log("sections for default12821 need to be put in standard defaults", obj)
      const defaultsRef = firebase.firestore().collection("defaults")
      const defaultSubsectionsRef = defaultsRef.doc(type).collection("subsections")
      const defaultSubsectionRef = defaultSubsectionsRef.doc(docId)
      defaultSubsectionRef.set(obj, { merge: true} )
      .then( console.log("defaults/TYPE/sunsections was updated with ", obj) )
      .catch( err => console.log(err) )
    },


    //guest
    saveGuestInfoIfUniqueEmailAndPhone( context,payload ) {
      console.log("saveGuestInfoIfUniqueEmailAndPhone ", payload)
      let exists = false
      let sameDocs = []
      //get for all guests info using the email provided in paylod
      const guestInfoRef = firebase.firestore().collection('guests')
      guestInfoRef.where('email', '==', payload.email).get()
      //loop over these to check for same phone number
      .then( (docs) => {
        docs.forEach( (doc) => {
          let obj = doc.data()
          if (obj.phone === payload.phone && obj.propertyId === payload.propertyId) { sameDocs.push(obj) }
        })
      })
      //mark 'exists' true or false
      .then( () => {
        console.log("exists? ",sameDocs.length)
        exists = sameDocs.length ? true : false
        // console.log("exists? ",exists)
      })
      //if 'exists' is false then create new record
      .then( () => {
        if ( !exists ) {
          let obj = payload
          obj.createdAt = Date.now()
          console.log("guest info obj ", obj)
          guestInfoRef.doc().set(obj)
          .then( console.log("Guest Info Saved", obj.email) )
          .catch ( (err) => console.log(err) )
        }
      })
    },
  },  
  modules: {Auth, Weather, Images, Nearby, BackgroundImages, Carditems},
})
