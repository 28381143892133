// SAMPLE:
//     <ehc-main-menu-edit  
//       @close="sectionPopup = !sectionPopup" 
//       :fields="cardItemsForLabelEdit" 
//       :cardItemsForFormData="cardItemsForFormData" 
//       type="propertyinfo"/>

<template>  
    <ehc-form v-model="formData" :meta="meta" @submit="update" class="mt-7">
    </ehc-form>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },  // fields to edit 
    cardItemsForFormData: {
      required: true,
        // type: Array, Caused console error - Invalid prop: type check failed for prop "cardItemsForFormData". Expected Array, got Object
    },  // card items to edit
  },
  data(){
    return {
        loading: false,
        formData: {},
      }
    },
  computed: {
    meta: function() {
      return this.fields.concat([
        {
          type: "submit",
          label: "Update",
          emitOnClick: "update",
          loading: this.loading
        },
      ])
    } 
  },
  watch: {
    cardItemsForFormData: function(val){
      this.formData = val
    }
  },
  methods: {
    update(){
      let formData = this.formData
      let newCardItems = this.$store.getters.cardItems.map( el => {
        console.log("EL ", el)
        if ( formData[el.id] ) {
          el.label = formData[el.id]
        }
        return el
      })
      let payload = {}
        payload.cardItems = newCardItems
        payload.propertyId = this.$store.getters.propertyId
      this.$store.dispatch("updateCustomCardItems", payload)
      this.close()
    },
    close: function(){
      this.$emit('close')
    },
    setFormData: function(){
      this.formData = this.cardItemsForFormData
    }
  },
  mounted(){
    this.setFormData()
  }
}

</script>

