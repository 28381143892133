<!---
    example:
      <ehc-dialog v-model="deletePrompt.show" title="Delete Page?" max-width="250px">
         are you sure you want to delete this page?
         <template v-slot:actions>
            <v-spacer></v-spacer>
            <ehc-btn  color="darkRed" @click="setDelete()" :loading="deletePrompt.loading">
               yes
            </ehc-btn>
            <ehc-btn @click="deletePrompt = !deletePrompt">cancel</ehc-btn>
          </template>
        </ehc-dialog>
--->

<template>

      <v-dialog v-model="show" 
        v-bind="$attrs" 
        content-class="ehc-dialog" 
        overlay-color="#1D3557"
        :overlay-opacity=".7"
        :width="width"
        >
        <v-card class="ehc-dialog">
            <v-toolbar dense  flat v-if="title !=null">
                <v-toolbar-title class="black2--text" >
                    <strong>{{title}}</strong>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                    <img src="@/assets/icons/Close-red.svg" @click="show=false" class="pointer" width="30"/>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="primary"
                    height="5"
                    striped
            ></v-progress-linear>

            </v-toolbar>
            <v-card-text class="pt-1 pb-1 black2--text">
                <slot></slot>
            </v-card-text>
            <v-card-actions>
                <slot name="actions"></slot>
                <template v-if="button">
                  <v-spacer/>
                  <v-btn color="button" width="40%" @click="$emit('buttonClick')" dark><strong>{{button}}</strong></v-btn>
                  
                </template>
            </v-card-actions>
        </v-card>
      </v-dialog>
</template>


<script>
export default {
    props: {
        value: Boolean,
        title: String,
        button: String,
        close: Boolean,
        loading: {type: Boolean, default: false},
        width: {default: '400px'},
    },
    name: 'ehc-dialog',
    data: () => ({
        show: false
    }),
    watch: {
        value(val) {
            if (val != this.show) {
                this.show= val
            }
        },
        show(val) {
            if (val != this.value) {
                this.$emit('input', val)
            }
        }
    }
}

</script>

<style>
.ehc-dialog .v-card,
.ehc-dialog.v-dialog{
    border-radius: 20px;
}

.ehc-dialog {
    overflow: auto !important;
}

*, ::before, ::after {
    background-repeat: no-repeat;
    box-sizing: border-box !important;
}

</style>