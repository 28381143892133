<template>
    <ehc-page>
      <component-avatar-property-name/>
        <ehc-card>
          <ehc-card-toolbar 
            v-if="propertyName || propertyAddress || userIsAuthenticated"
            :title="propertyName"
            :edit=userIsAuthenticated
            @edit = "goToRoute('PropertyNew')"
            fat
            >
              <template #subtitle>

                <span v-if="!property.showAddress">{{property.subtitle}}</span>
                <span v-if="property.showAddress && showDisplayAddress && showLink" @click="visitUrl(displayAddressUrl)" class="pointer ehc-address" ><img height="24px" src="@/assets/icons/Location@3x.svg" class="mr-3 "/>{{displayAddress()}}</span>
                <span v-if="property.showAddress && showDisplayAddress && !showLink" class="ehc-address">{{displayAddress()}}</span>
              </template>
            </ehc-card-toolbar>

            <!-- //TODO: Need to make the edit button show when showName = false -->
            <div v-if="userIsAuthenticated">
              <span class="text-caption blue-grey--text lighten-4--text">{{propertyId}}</span>
            </div>

            <ehc-button-small v-if="!cardItemsFiltered.length && userIsAuthenticated" @submit="resetUseCardItems()" :loading = "loading">Reload Menu</ehc-button-small>

            <ehc-menu 
              v-model="cardItemsFiltered" 
              v-on:click="goToPageName" 
              v-if="triggerTransition"
              :class="menuClass"
              />

          <!-- // button to show main menu items edit -->
          <ehc-button-small 
              v-if="(orgIsPremium && userIsAuthenticated) || userIsAdmin"
              @submit="editMainMenuItems" 
              icon="edit.svg" 
              text="Menu Labels" 
              type="propertyinfo">
          </ehc-button-small>

        </ehc-card>

        <!-- // display a popup that edits main menu item labels -->
        <ehc-message v-model="message" close title="Edit Main Menu Labels" v-if="(orgIsPremium && userIsAuthenticated) || userIsAdmin">
          <template #editPageNames>
            <ehc-main-menu-edit  
              @close="close" 
              :fields="cardItemsForLabelEdit" 
              :cardItemsForFormData="cardItemsForFormData" 
              type="propertyinfo"
              />
          </template>
        </ehc-message>

      <component-weather class="px-3 pt-0"></component-weather>  
    </ehc-page>
</template>

<script>
import mixins from "@/mixins"
import ComponentAvatarPropertyName from '../components/component-avatar-property-name.vue'
import ComponentWeather from '../components/component-weather.vue'
import EhcMenu from '../components/ehc-menu.vue'
import EhcCard from '../components/ehc-card.vue'
import EhcMainMenuEdit from '../components/ehc-main-menu-edit.vue'
import FB from 'firebase'

  export default {
    name: 'Home',
    mixins: [mixins],
    components: {
      ComponentAvatarPropertyName,
      ComponentWeather,
      EhcMenu,
      EhcCard,
      EhcMainMenuEdit,
    },
    data() {
      return {
        triggerTransition: false,
        toolBarBackground: "transparent",
        propertyNameFontColor: "black",
        sectionPopup:false,
        message: {
          show: false,
        },
        showDisplayAddress: true,
        showLink: null,
        loading: false,
      }
  },
    computed: {
      cardItems: function() {
        return this.$store.getters.cardItems
      },
      cardItemsFiltered: function(){
        let property = this.property
        let cardItemsFiltered = this.cardItems.filter( el => {
            if ( !el.hideIfInvalidField ) { 
              return true 
              }
            return this.isValidUrl(property[el.hideIfInvalidField])
          }
        )
        return cardItemsFiltered
      },
      cardItemsForLabelEdit: function(){
        let cardItemsForEdit = []
        this.cardItems.forEach( el => {
        let obj = {}
            obj.type = "text"
            obj.label = el.label
            obj.key = el.id
            // commenting out the following line allows all menu items to be edited
            // if ( el.editable || el.id === "bookagain" ){
            //   cardItemsForEdit.push(obj)
            // }
            cardItemsForEdit.push(obj)
          })
        return cardItemsForEdit
      },
      cardItemsForFormData: function(){
        let obj ={}
        this.cardItemsFiltered.forEach( el => {
          obj[el.id] = el.label
          })
        return obj
      },
      cssHomeClass: function() {
        let color = this.property.backgroundColor
        let homeClass = `home ${color} text-center` 
        return homeClass
      },
      cssTitleClass: function(){
        return `mb-5 ${this.propertyNameFontColor}--text text--darken-4 text-h5 text-sm-h4 text-md-h3`
      },
      propertyId: function(){
        return this.$store.getters.propertyId
      },
      property: function(){
        return this.$store.getters.property
      },
      pageImage: function(){
        let homeImage =  this.$store.getters.images.filter( (el) => {
          return el.homeImage === true
        })
        // console.log(homeImage.length)
        if ( homeImage.length ) { return homeImage[0] }
        return this.$store.getters.images[0]
      },
      pageTitle: function() {
        return this.$store.getters.property.name
      },
      backgroundImages: function(){
        return this.$store.getters.backgroundImages
      },
      background: function(){
        if ( this.$store.getters.temporaryBackground !== null ) { return this.$store.getters.temporaryBackground }
        return this.property.backgroundColor
      },
      avatarSize: function() {
        if ( window.outerWidth < 600 ) {
          return 150
        }
       return 125
      },
      propertyName: function() {
        let property = this.property
        if ( property.showName === false ) { return "" }
        return property.name
      },
      propertyAddress: function() {
        let property = this.property
        //if mapAddress is not defined, return empty string
        if ( property.mapAddress === undefined ) { return "" }
        if ( property.mapAddress.formatted_address === undefined ) { return "" }
        //if showAddress is false, return city, state, country
        if ( property.showAddress === false && property.showName === false ) { return "" }  
        if ( property.showAddress === false ) {
          let address = ""
          if ( property.mapAddress.city ) { address += `${property.mapAddress.city}, ` }
          if ( property.mapAddress.state ) { address += `${property.mapAddress.state}, ` }
          if ( property.mapAddress.country ) { address += `${property.mapAddress.country} ` }
          return address 
          }
        //if showAddress is true and mapAdress is defined, return formatted_address
        return property.mapAddress.formatted_address
      },
      display: function(){
        return {
          name: true,
          address: true,
        }
      },
      displayAddressUrl: function(){
        let property = this.property
        if ( property.mapAddress === undefined ) { return "" }
        if ( property.mapAddress.formatted_address === undefined ) { return "" }
        let address = property.mapAddress.formatted_address
        let url = `https://www.google.com/maps/search/?api=1&query=${address}`
        return url
      },
      menuClass: function(){
        let menuClass = null
        !this.propertyName && !this.propertyAddress ? menuClass = "pt-8" : menuClass = null
        return menuClass
      },
    },
    watch: {
      //TODO: not sure this is still needed
      // property: function(){
      //   this.setPropertyNameFontColor()
      // },
      // cardItemsForLabelEdit: function(val){
      //   // console.log("cardItemsForLabelEdit ",val)
      //   // TODO: this is a hack to get the menu to update to custom labels
      //    if ( !this.property.useCustomCardItems ) {
      //     this.loadCustomCardItems() 
      //   }
      // },

    },
    methods: {
      isValidUrl: function(url) {
        if (!url) { return false }
        try {
          new URL(url);
        } catch (e) {
          console.error("e ",e);
          return false;
        }
        return true;
      },
      //TODO: not sure this is still needed
      setPropertyNameFontColor(){
        let backgroundColor = this.$route.query.background || this.property.backgroundColor
        this.propertyNameFontColor = this.backgroundImages.filter( (el) => el.name === backgroundColor )[0].propertyNameColor
      },
      goToPageName: function(menuItem){
        //console.log"goToPageName ",menuItem)

        const routeName = menuItem.link

        // go directly to the book again link
        if ( routeName === "BookAgain" || routeName.includes("http") ) { 
          this.visitUrl(this.property.bookagain) 
        }

        // Show a 'comming soon' message
        else if ( routeName === 'Coming Soon' ) {
          this.$store.commit('setShowAlert',true)
          this.$store.commit('setAlertMessage','Coming Soon')
        }   

        // this option is added to customize the section title to fit the custom label  
        else if ( this.property.useCustomCardItems ) {
          this.$router.push({ name: routeName, query: { pageTitle: menuItem.label } })
        }

        // default action
        else { this.goToRoute(routeName) }
      },
      addPropertyIdToUrl: function() {
        //console.log"adding propertyId to url")
        //console.logthis.$route.path)
        if ( window.location.href.includes(this.propertyId) ) { return }
        history.replaceState(
          {},
          null,
          window.location.href + this.propertyId
        )
      },
      //TODO: used one time to build defaults in firestore for cardItems - can probably be deleted
      setCardItems: function(){
        let db = FB.firestore().collection('defaults').doc('cardItems').collection("subsections")
        this.cardItems.forEach(el => {
        db.doc(el.label.toLowerCase().replace(/\s/g, '')).set(el)
        })
      },
      loadCustomCardItems: async function(){
        await this.$store.dispatch('loadCustomCardItems', this.propertyId) //load custom card items from firestore defaults into property customCardItems collection
        await this.$store.dispatch('updateProperty', {useCustomCardItems: true, mergeData: true}) //sets the useCustomCardItems to true in the property
        this.$store.dispatch('getCustomCardItems', this.propertyId) //loads custom card items from firestore property customCardItems collection into this.$store.getters.customCardItems
      },
      editMainMenuItems: async function(){
        if ( !this.property.useCustomCardItems ) {
          await this.loadCustomCardItems() //load card items from defaults into customCardItems collection then set useCustomCardItems to true in property then load custom card items from customCardItems collection into this.$store.getters.customCardItems
        }
        this.message = {
          title: "Edit page labels",
          color: "info",
          show: true,
          slotName: "editPageNames"
        }
      },
      close: function(){
        //console.log"close")
        this.message.show = false
      },
      displayAddress: function(){
        // if there is an error, return empty string and set showDisplayAddress to false
        try {
          //if mapAddress is not defined, return empty string
          if ( this.property.mapAddress === undefined ) { this.showDisplayAddress = false; return "" }

          //destructure mapAddress
          let mapAddress = this.property.mapAddress
          let { address, city, state } = mapAddress
          let { showName, showAddress} = this.property

          //if showName or showAddress is undefined, set to true
          if ( showName !== false ) { showName = true }
          if ( showAddress !== true ) { showAddress = false }

          //set showLink = showAddress
          this.showLink = showAddress

          //if both showAddress and showName are false, return empty string
          if ( showName === false && showAddress === false ) { this.showDisplayAddress = false; return "" }

          // build displayAddress
          let displayAddress = ""

          // //if showAddress is true add city, state to displayAddress
          if ( city ) { displayAddress += `${city}, ` }
          if ( state ) { displayAddress += `${state}, ` }

          //if showAddress is true, add address to displayAddress
          if ( address && showAddress ) { displayAddress = `${address}, ` }

          // Clean up displayAddress

          // remove 'undefined' from displayAddress
          displayAddress = displayAddress.replace("undefined","")

          // remove ', ' from end of displayAddress
          displayAddress = displayAddress.replace(/,\s*$/, "")

          return displayAddress
        } catch (e) {
          console.error("error in displayAddress ",e)
          this.showDisplayAddress = false
          return ""
        }
      },
      resetUseCardItems: async function(){
        this.loading = true
        await this.$store.dispatch('updateProperty', {useCustomCardItems: false, mergeData: true}) //sets the useCustomCardItems to false in the property
        this.$store.dispatch('getCardItems') //loads card items from firestore defaults into this.$store.getters.cardItems
        this.loading = false
      },
    },
    async created(){
      // if property useCustomCardItems is true, get card items from property customCardItems collection instead of defaults
      if ( this.property.useCustomCardItems ) {
        //console.log"using custom card items")
        this.$store.dispatch('getCustomCardItems', this.propertyId)
      } else {
        this.$store.dispatch('getCardItems')
      }
      //TODO: I think this is not needed anymore, but I'm leaving it here for now
      this.$store.dispatch("getImages", this.propertyId)
      //TODO: I think this is not needed anymore, but I'm leaving it here for now
      if ( this.$route.query.background ) { this.$store.commit("setTemporaryBackground",this.$route.query.background) }
      this.$store.commit('setPopup',false)
    },    
    mounted(){
      this.triggerTransition = true
      this.addPropertyIdToUrl()
    }
  }
</script>

<style>
.mainmenuedit {
  width:400px !important;
}


.ehc-address {
  /* center align contents */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:5px;

}

.property-card-toolbar {
  margin-top:25px !important;
}

</style>