import Vue from 'vue'
import App from './App.vue'
import firebase from 'firebase'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import wysiwyg from 'vue-wysiwyg'
// import CKEditor from 'ckeditor4-vue'
import ehcCard from '@/components/ehc-card.vue'
import ehcCardContent from '@/components/ehc-card-content.vue'
import ehcCardToolbar from '@/components/ehc-card-toolbar.vue'
import ehcMenu from '@/components/ehc-menu.vue'
import ehcForm from '@/components/ehc-form.vue'
import ehcPage from '@/components/ehc-page.vue'
import ehcDrawer from '@/components/ehc-drawer.vue'
import ehcDialog from '@/components/ehc-dialog.vue'
import ehcMessage from '@/components/ehc-message.vue'
import ehcButtonSmall from '@/components/ehc-button-small.vue'
import VueTheMask from 'vue-the-mask'
import SocialSharing from 'vue-social-sharing'
import ehcBtn from '@/components/ehc-btn.vue'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

 

Vue.config.productionTip = false

Vue.component('ehc-card', ehcCard)
Vue.component('ehc-card-content', ehcCardContent)
Vue.component('ehc-card-toolbar', ehcCardToolbar)
Vue.component('ehc-menu', ehcMenu)
Vue.component('ehc-form', ehcForm)
Vue.component('ehc-page', ehcPage)
Vue.component('ehc-dialog',ehcDialog)
Vue.component('ehc-drawer',ehcDrawer)
Vue.component('ehc-button-small',ehcButtonSmall)
Vue.component('ehc-btn',ehcBtn)
Vue.component('ehc-message',ehcMessage)


Vue.use(SocialSharing)

Vue.use(VueTheMask)

Vue.use(autoAnimatePlugin)

const firebaseConfig = {
  apiKey: "AIzaSyCBdJqwfDdjwB75S2MsjKkJelQdw1ggNUc",
  authDomain: "everhostio.firebaseapp.com",
  databaseURL: "https://everhostio-default-rtdb.firebaseio.com",
  projectId: "everhostio",
  storageBucket: "everhostio.appspot.com",
  messagingSenderId: "355449850402",
  appId: "1:355449850402:web:4ee9935f1cb5e947b1587b",
  measurementId: "G-ZLJS89XE0W"
};

const firebaseConfigDev = {
  apiKey: "AIzaSyD_b46PYlea0ZdTl-op1vyezssQyrSteTM",
  authDomain: "dev-everhostio.firebaseapp.com",
  projectId: "dev-everhostio",
  storageBucket: "dev-everhostio.appspot.com",
  messagingSenderId: "333205235104",
  appId: "1:333205235104:web:b2e0a905dc7ecc929570c5"
};

firebase.initializeApp(firebaseConfig);

// if ( window.location.hostname.includes('localhost') ) {
//   firebase.firestore().useEmulator('localhost', 8088);
//   firebase.functions().useEmulator('localhost', 5060);
// }

const analytics = firebase.analytics()

let isAuthenticated = firebase.auth().currentUser

const app = new Vue({
  router,
  store,
  vuetify,
  // wysiwyg,
  render: h => h(App)
})



// .$mount('#app')

firebase.auth().onAuthStateChanged(user => {
  // console.log("onAuthStateChanged: ", user)
  store.dispatch("fetchUser", user)
  app.$mount('#app')
});


// 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