<template>
<ehc-card>
  <ehc-card-toolbar :title="pageTitle">
    <template #right>
      <img src="@/assets/icons/Close@3x.svg" @click="$emit('closeShare')">
    </template>  
  </ehc-card-toolbar>
  <ehc-card-content v-for="(network, index) in networks" :key=index @click="shareLink(item)">
      <ShareNetwork
        :network=network.network
        :url=network.url
        :title=network.title
        :description=network.description
        :quote=network.quote
        :hashtags=network.hastags
        style="text-decoration: none; color: inherit;"
        >
          <v-card class="px-3 py-3 mx-auto">
            {{network.label}}
            <v-icon class="float-right" :color="network.color"  right>{{network.icon}}</v-icon>
          </v-card>  
      </ShareNetwork>
  </ehc-card-content>
  <ehc-card-content>
          <v-card class="px-3 py-3 mx-auto pointer font-weight-bold" @click="link">
            Link 
            <v-icon class="float-right" color="black"  right>mdi-link</v-icon>
          </v-card>
   </ehc-card-content>

   <ehc-dialog v-model="showLink" title="Share this link" max-width = "300px" close>
     <span class="font-weight-bold text-center">{{linkToShare}}</span>
     <p class="text-caption text-center">...has been saved to your clipboard. Paste it into a text or email.</p>
   </ehc-dialog>  

</ehc-card>
</template>

<script>
import mixins from '@/mixins'
import ehcCardToolbar from './ehc-card-toolbar.vue'
import EhcCardContent from './ehc-card-content.vue'

export default {
  components: { ehcCardToolbar, EhcCardContent },
  mixins: [mixins],
  data() {
    return {
      pageTitle: "Share this place...",
      showLink: false
    }
  },
  computed: {
    propertyId: function() {
      return this.$store.getters.propertyId
    },
    avatarImg: function() {
      return this.$store.getters.property.avatarImg
    },
    property: function() {
      return this.$store.getters.property
    },
    networks: function(){
      let networksArr = [
        {
            label: "Facebook",
            network: "facebook",
            url: `https://everhost.io/${this.propertyId}`,
            title: "Everhost... the best way to help your guests enjoy!",
            description: "Everhost is convienient way for property managers to help their guests know everthing about the property and location.",
            quote: "Evehrhost Rocks!",
            hashtags: "airbnb, vrbo",
            icon: "mdi-facebook",
            color: "#FFBF00"
        },
        {
            label: "WhatsApp",
            network: "WhatsApp",
            url: `https://everhost.io/${this.propertyId}`,
            title: "Everhost... the best way to help your guests enjoy!",
            description: "Everhost is convienient way for property managers to help their guests know everthing about the property and location.",
            icon: "mdi-whatsapp",
            color: "#985B5D"
        },
        {
            label: "Email",
            network: "email",
            url: `https://everhost.io/${this.propertyId}`,
            title: "Everhost... the best way to help your guests enjoy!",
            description: "Everhost is convienient way for property managers to help their guests know everthing about the property and location.",
            icon: "mdi-email",
            color: "#4B2D87",
        },
        {
            label: "Text",
            network: "sms",
            url: `https://everhost.io/${this.propertyId}`,
            title: "Everhost... the best way to help your guests enjoy!",
            description: "Everhost is convienient way for property managers to help their guests know everthing about the property and location.",
            icon: "mdi-message-outline",
            color: "#95533E"
        },
      ]
      return networksArr
    },
    socialMedia: function(){
      let socialArray = [
          {
            name: "Facebook",
            type: "url",
            link: `https://www.facebook.com/sharer/sharer.php?u=${this.$store.getters.baseUrl}`
          },
        ]
      return socialArray
    },
    linkToShare: function() {
      return `${this.$store.getters.baseUrl}${this.$store.getters.propertyId}`
    }
  },
  methods: {
    shareLink: function(item){
      if (item.type === "url") {
        this.visitUrl(item.link,'_self')
      }
    },
    link: function(){
      navigator.clipboard.writeText(this.linkToShare)
      this.showLink = !this.showLink
    },
  },

}
</script>
