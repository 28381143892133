<template>
  <div class="bgImage">
    <!-- first use the property.companyBackgroundImage to select the background image -->
    <v-img
      v-if="property.companyBackgroundImage"
      :src="property.companyBackgroundImage"
      class="bgFixed"
      position="center center"
      width="100%"
    >
      <slot></slot>
    </v-img>

    <!-- then use the organization.companyBackgroundImage to select the background image -->
    <v-img
      v-else-if="organization.companyBackgroundImage"
      :src="organization.companyBackgroundImage"
      class="bgFixed"
      position="center center"
      width="100%"
    >
      <slot></slot>
    </v-img>

    <!-- then use the default images from firebaseStorage to select the background image-->
    <v-img
      v-else
      :src="bgImage.fileName"
      :lazy-src="require('@/assets/img/' + lazyFileName)"
      class="bgFixed"
      position="center center"
      width="100%"
    >
      <slot></slot>
    </v-img>

    <!-- last use the default images assets to select the background image-->
    <!-- <v-img
      v-else
      :src="getImgSrc(bgImage.fileName)"
      class="bgFixed"
      :position="bgImage.position"
      :lazy-src="require('@/assets/img/' + lazyFileName)"
    >
      <slot></slot>
    </v-img> -->
  </div>
</template>

<script>
// the position value in v-img is the same as "background-position" as described here
// https://developer.mozilla.org/en-US/docs/Web/CSS/background-position
// bgFixed triggers the css in the style section bellow

export default {
  mixins: [],
  props: [],
  components: {},
  data: () => ({
    //default background image
    bgImage: {
      fileName: "app-bg-blue.png",
      lazyFileName: "app-bg-blue.png",
      name: "default",
      position: "bottom",
      propertyNameColor: "blue-grey",
      tags: "scene, nature",
      location: "@/assets/img/",
    },
    fireStorage:
      "https://firebasestorage.googleapis.com/v0/b/everhostio.appspot.com/o/backgroundImages%2F",
    accessToken:
      "?alt=media&token=fa070e06-704a-4adb-a6a0-66d764d2dc53&_gl=1*1tw95o6*_ga*MTgxODU4MjIxMS4xNjg2NjY3NjQz*_ga_CW55HF8NVT*MTY5NTk4NzQ1OS4xMjIuMS4xNjk1OTg4Mjk0LjQzLjAuMA",
  }),
  watch: {
    //TODO: Test to see if this is needed - onCreated appears to work OK
    property: function () {
      this.bgImage = this.getBackgroundImage(this.bgImages);
      // console.log(("bgImage from watcher ",this.bgImage)
    },
  },
  created() {
    try {
      this.bgImage = this.getBackgroundImage(this.bgImages);
      // console.log(("bgImage from created ",this.bgImage)
    } catch (err) {
      console.error(err);
    }
  },
  computed: {
    lazyFileName: function () {
      if (this.bgImage.lazyFileName) {
        return this.bgImage.lazyFileName;
      } else {
        return this.bgImage.fileName;
      }
    },
    bgImages: function () {
      let bgImages = this.$store.getters.backgroundImages;
      // TODO: useFireStorageBackground is set in data but also can be overriden in settings firestore doc
      bgImages.map((el) => {
        if (
          el.location === "firebasestorage" &&
          !el.fileName.includes("http")
        ) {
          el.fileName = `${this.fireStorage}${el.fileName}${this.accessToken}`;
        }
        return el;
      });
      return bgImages;
    },
    property: function () {
      return this.$store.getters.property;
    },
    organization: function () {
      return this.$store.getters.organization;
    },
  },
  methods: {
    getBackgroundImage(array) {
      let bgImageArr = array.filter(
        (el) => el.name === this.property.backgroundColor
      );
      if (bgImageArr.length) {
        return bgImageArr[0];
      } else {
        return this.bgImage;
      } // use default from data is there is no image for the backgroundColor
    },
    getImgSrc(fileName) {
      // Check if the file exists before using require
      try {
        return require('@/assets/img/' + fileName);
      } catch (error) {
        // Handle the case when the file doesn't exist
        return fileName; // Provide a default image
      }
    },
  },
};
</script>

<style>
.bgFixed > .v-image__image {
  background-attachment: fixed !important;
  min-height: 100%;
}

.bgImage {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bgFixed {
  flex: 1;
}
</style>


