<template>
  <v-app
    v-touch="{
      right: () => swipe('right'),
      left: () => swipe('left'),
    }"
    v-if="$route.params.propertyId || $store.getters.propertyId"
  >
    <ehc-admin v-if="userIsAuthenticated" :message="message"></ehc-admin>

    <ehc-splash
      v-if="showSplashScreen && !splashScreenEnterPressed"
      @enter="splashScreenEnterPressed = true"
      :city="city"
      :welcomeText="property.welcomeText"
      :authenticated="userIsAuthenticated"
    />

    <component-bg-image v-show="!showSplashScreen || splashScreenEnterPressed">
      <v-container max-width="500px" class="pa-0 mx-xs-0">
        <component-tool-bar :color="toolBarBackground" />

        <v-main>
          <router-view :key="$route.fullPath" />
        </v-main>

        <component-footer-menu />
      </v-container>
    </component-bg-image>

    <ehc-alert></ehc-alert>
  </v-app>
</template>

<script>
import ComponentFooterMenu from "@/components/component-footer-menu.vue";
import componentToolBar from "@/components/component-tool-bar.vue";
import componentBgImage from "@/components/component-bg-image.vue";
import EhcAdmin from "@/components/ehc-admin.vue";
import mixins from "@/mixins";
import nearby from "@/mixins/nearby.js";
import GuestInfo from "@/views/GuestInfo.vue";
import updateMixin from "@/mixins/updateMixin";
import api from "@/mixins/api.vue";
import ehcSplash from "@/components/ehcSplash.vue";

import EhcAlert from "@/components/ehc-alert.vue";

export default {
  name: "App",
  mixins: [mixins, updateMixin, nearby, api],
  data: () => ({
    showSplashScreen: false,
    splashScreenEnterPressed: false,
    toolBarBackground: "transparent",
    appLoading: true,
    overlay: false,
    overlayMessage:
      "<p>You have not provided a valid property id.</p><p> Check with the property owner or manager for the guidebook link.</p>",
  }),
  components: {
    componentToolBar,
    ComponentFooterMenu,
    componentBgImage,
    EhcAdmin,
    GuestInfo,
    EhcAlert,
    ehcSplash,
  },
  methods: {
    goToPublicSite() {
      if (!this.$route.params.propertyId) {
        window.location.replace("https://everhost.io/");
      }
    },
    checkForPropertyId() {
      const propertyId = this.$route.params.propertyId;
      const defaultPropertyId = this.$store.getters.defaultPropertyId;

      // If a bad property id is passed in, redirect to the public site
      if (
        propertyId &&
        propertyId.length !== 7 &&
        propertyId !== defaultPropertyId
      ) {
        this.redirectToHelloSite();
        return false;
      }

      // If a good property id is passed in, continue
      if (propertyId) {
        this.initProperty(propertyId);
        sessionStorage.setItem("EHPropertyId", propertyId);
        return;
      }

      // If no property id is passed in, check session storage for a property id
      const storedPropertyId = sessionStorage.getItem("EHPropertyId");
      if (storedPropertyId) {
        this.initProperty(storedPropertyId);
        return;
      }

      // If no property id is passed in and no session storage, redirect to the public site
      console.log("routing to hello");
      this.redirectToHelloSite();
    },
    // Helper function to redirect to hello site
    redirectToHelloSite() {
      this.visitUrl("https://hello.everhost.io/", "_self");
      this.appLoading = false;
    },

    async initProperty(propertyId) {
      try {
        this.logGuestVisit(propertyId);
      } catch (error) {
        console.log(error);
      }
      this.$store.commit("setPropertyId", propertyId);
      await this.$store.dispatch("getProperty").then(() => {
        if (!this.property.propertyId || this.property.deletedAt) {
          this.visitUrl("https://hello.everhost.io/", "_self");
        }
        this.appLoading = false;
      });
    },

    //This logs a browser session initiated by a guest user (not authenticated)
    logGuestVisit(propertyId) {
      if (
        !this.userIsAuthenticated &&
        !sessionStorage.getItem("EHPropertyId")
      ) {
        this.apiLogGuestVisit(propertyId);
      }
    },

    swipe: function (direction) {
      if (this.$store.getters.popup) {
        this.$store.commit("setPopup", false);
        return;
      }
      if (direction === "right") {
        console.log(direction);
        this.$router.go(-1);
      } else {
        console.log(direction);
        this.$router.replace({ name: "Home" });
      }
    },
    browserBackButton: function () {
      console.log("browser back button");
    },
  },
  computed: {
    property: function () {
      return this.$store.getters.property;
    },
    propertyId: function () {
      return this.$store.getters.propertyId;
    },
    userId: function () {
      if (this.$store.getters.user.data) {
        return this.$store.getters.user.data.uid;
      }
      return null;
    },
    message: function () {
      const message = {};
      message.text = this.property.publishedAt ? "Published" : "Unpublished";
      message.toolTip = this.property.publishedAt
        ? `<span style="text-align:center">Anyone can view this guidebook.</span>`
        : `<span style="text-align:center">Only you can view this guidebook.</span>`;
      return message;
    },
    city: function () {
      return this.property.mapAddress
        ? this.property.mapAddress.city || ""
        : "";
    },
  },

  watch: {
    property: function () {
      console.log("property changed", this.property);
      if (!this.property) {
        console.log("routing to hello");
        this.visitUrl("https://hello.everhost.io/", "_self");
      }
      //if this new property is copied from an existing property, it copies areaguie and propertyinfo from the existing property rather than standard defaults
      //
      if (this.property.copiedFrom) {
        let propertyCopiedFromId = this.property.copiedFrom;
        console.log("using copied property for sections");
        this.$store
          .dispatch("getCustomSubsections", {
            type: "propertyinfo",
            propertyId: propertyCopiedFromId,
          })
          .then(() =>
            this.$store.dispatch("updateSections", {
              type: "propertyinfo",
              sections: this.$store.getters.subsections,
            })
          );
        this.$store
          .dispatch("getCustomSubsections", {
            type: "areaguide",
            propertyId: propertyCopiedFromId,
          })
          .then(() =>
            this.$store.dispatch("updateSections", {
              type: "areaguide",
              sections: this.$store.getters.areaGuide,
            })
          );
        //TODO: CLean up this code
        // let payload = {sourceDocId: propertyCopiedFromId, targetDocId: this.propertyId, sourceCollectionPath: 'cardItems', targetCollectionPath: 'customCardItems'}
        // console.log(payload)
        // this.$store.dispatch( 'copyCollection', payload)
        // .then( () => this.$store.dispatch( 'getCustomCardItems', this.propertyId )  )
        this.apiUpdateProperty(this.propertyId, {
          copiedFrom: null,
          copiedFromArchive: propertyCopiedFromId,
        });
      }
      // this.clearUpdatedAt(this.property.propertyId)
      // this.prefetchNearby(this.property.propertyId)

      //Get the organization doc and put it in the store
      if (this.property.orgId) {
        this.$store.dispatch(
          "getOrganizationFromPropertyOrgId",
          this.property.orgId
        );
      }

      if (this.property.showSplashScreen && this.property.publishedAt) {
        this.showSplashScreen = true;
      } else {
        this.showSplashScreen = false;
      }
    },
  },
  created() {
    console.log(
      "%c====> DEPLOYED ON 03-30-2025 3:21 PM TDA <====",
      "color: white; background: green;"
    );
    //check propertyId in route params and redirect to hello site if not valid
    this.checkForPropertyId();
    // this.$store.dispatch( 'getNearbyCards' )
    this.$store.dispatch("getCustomSubsections", { type: "propertyinfo" });
    this.$store.dispatch("getCustomSubsections", { type: "areaguide" });

    // if route params includs showCardWidthData set a session variable to true - used to debug branding card width issues
    if (this.$route.query.showCardWidthData === null) {
      sessionStorage.setItem("showCardWidthData", true);
    }
    this.apiGetAppSettings();

    //TODO: CLean up this code
    // if ( this.property.copiedFrom ) {
    //     let propertyCopiedFromId = this.property.copiedFrom
    //     let payload = {sourceDocId: propertyCopiedFromId, targetDocId: this.propertyId, sourceCollectionPath: 'cardItems', targetCollectionPath: 'customCardItems'}
    //     console.log(payload)
    //     this.$store.dispatch( 'copyCollection', payload)
    //     .then( () => this.$store.dispatch( 'getCustomCardItems', this.propertyId )  )
    // }

    // let test = this.$store.dispatch( 'copyCollection',{sourceDocId: '6496167', targetDocId: '7276789', sourceCollectionPath: "customCardItems", targetCollectionPath: "customCardItems"} )
    // console.log("test", test)
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}

.application {
  font-family: "Sailec";
}

a {
  color: rgb(71, 113, 250) !important;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: rgb(15, 5, 128) !important;
}

.v-main {
  padding-bottom: 0px !important;
}

/* new fonts from completed design */
@font-face {
  font-family: "Sailec Black";
  src: url("~@/assets/fonts/Sailec Black.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sailec";
  src: url("~@/assets/fonts/Sailec Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Sailec";
  src: url("~@/assets/fonts/Sailec Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sailec Medium";
  src: url("~@/assets/fonts/Sailec Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sailec Light";
  src: url("~@/assets/fonts/Sailec Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sailec";
  src: url("~@/assets/fonts/Sailec Thin.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}
</style>
