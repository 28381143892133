import Axios from "axios";

export default {
  state: {
    weather_api_key: "e8b09e398ed74e45c9b070b0de07788e",
    weather_url_base: "https://api.openweathermap.org/data/2.5/",
    weather_city: "Detroit",
    weatherObj: {},
    forecastArray: [],
  },
  getters: {
    apiKey: (state) => state.apiKey,
    weather_url_base: (state) => state.weather_url_base,
    weather_city: (state) => state.city,
    weatherObj: (state) => state.weatherObj,
    forecastArray: (state) => state.forecastArray,
    city: (state, getters, rootState, rootGetters) => rootGetters.property.city,
  },
  mutations: {
    setWeatherObj(state, payload) {
      state.weatherObj = payload;
    },
    setForecastArray(state, payload) {
      state.forecastArray = payload;
    },
  },
  actions: {
    // getWeatherObj: async function( context, payload ){
    //console.log"isthis needed")
    // if ( context.getters.property.mapAddress ) {
    //   //console.log"getWeatherObj property.mapAddress", payload)
    //   let mapAddress = context.getters.property.mapAddress
    //   //console.log"mapAddress Exists")
    //   let obj = {
    //     lat: mapAddress.lat,
    //     lng: mapAddress.lng,
    //     name: mapAddress.city
    //   }
    //   if ( context.getters.property.unit_of_temperature ) { obj.units = context.getters.property.unit_of_temperature }
    //   obj.new = true
    //   //console.log"New Weather Obj", obj)
    //   context.commit("setWeatherObj", obj)
    // } else {
    //   //console.log"getWeatherObj from openweather api", payload)
    //   const url = 'https://api.openweathermap.org/data/2.5/'
    //   const city = payload.city
    //   let q = city
    //   const appid = 'e8b09e398ed74e45c9b070b0de07788e'
    //   return await Axios.get(`${url}weather?q=${q}&units=imperial&appid=${appid}`)
    //   .then( (res) => {
    //     //console.logres)
    //     context.commit("setWeatherObj", res)
    //     return res
    //   }).catch( (err) => console.log(err) )
    // }
    // },
    getForecastArray: function (context, payload) {
      return new Promise((resolve, reject) => {
        const url = "https://api.openweathermap.org/data/3.0/";
        const lat = payload.lat;
        const lon = payload.lng;
        const appid = "e8b09e398ed74e45c9b070b0de07788e";
        const exclude = "current,minutely,hourly";
        const units = payload.units || "imperial";
        const url2 = `${url}onecall?lat=${lat}&lon=${lon}&exclude=${exclude}&units=${units}&APPID=${appid}`;
        //console.logurl2)
        Axios.get(url2)
          .then((res) => {
            let arr = res.data.daily;
            //console.log"forecast array: ", arr)
            context.commit("setForecastArray", arr);
            resolve(res);
          })
          .catch((err) => console.log("Error: ", err));
      });
    },
  },
};
