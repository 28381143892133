<template>
    <!-- <transition name="slide-up">     -->
        <v-card 
            color="rgb(255, 255, 255, .7)" 
            :class="cardClass + ' ehc-card'" 
            flat 
            transition="slide-y-transition" 
            v-if="triggerTransition"
            v-bind="$attrs"
            :height="height">
            <slot></slot>
        </v-card>
    <!-- </transition> -->
</template>

<script>

    export default {
        mixins: [],
         props: {
            dialog:     {type: Boolean, default: false}, 
            height: String,
        },
        components: {

        },
        data: () => ({
            triggerTransition: false,
        }),
        created () {
        },  
        watch: {

        },
        computed: {
            cardClass: function() {
                //I don't I was gonna add a feature for making this a dialog, but we dont need this any more
                //it's not hurting anything so I'll leave it. 
                if (this.dialog) {
                    return ""
                } else {
                    return ""
                }
            }

        },
        methods: {
        },
        mounted(){
            this.triggerTransition = true
        }
    }
</script>

<style scoped>

.ehc-card {
    background-color: #FFFFFF !important;
    border-radius: 0 !important;
    padding-bottom: 20px;
    margin: 0 auto;
    margin-bottom: 25px;
    max-width: 900px;
}
</style>


