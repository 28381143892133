import { render, staticRenderFns } from "./component-avatar-property-name.vue?vue&type=template&id=1f897256&scoped=true"
import script from "./component-avatar-property-name.vue?vue&type=script&lang=js"
export * from "./component-avatar-property-name.vue?vue&type=script&lang=js"
import style0 from "./component-avatar-property-name.vue?vue&type=style&index=0&id=1f897256&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f897256",
  null
  
)

export default component.exports